import { testimonialConstants } from "../actions/types";

const initialState = { loading: false, data: [], error: null };

const testimonialReducer = (state = initialState, action) => {
  switch (action.type) {
    case testimonialConstants.GET_TESTIMONIAL_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: "",
      };
    case testimonialConstants.GET_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.testimonials,
        error: "",
      };
    case testimonialConstants.GET_TESTIMONIAL_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    case testimonialConstants.CREATE_TESTIMONIAL_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case testimonialConstants.CREATE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case testimonialConstants.CREATE_TESTIMONIAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case testimonialConstants.UPDATE_TESTIMONIAL_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case testimonialConstants.UPDATE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case testimonialConstants.UPDATE_TESTIMONIAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case testimonialConstants.DELETE_TESTIMONIAL_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case testimonialConstants.DELETE_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case testimonialConstants.DELETE_TESTIMONIAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return { ...state };
  }
};

export default testimonialReducer;
