import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import CircularProgress from "@material-ui/core/CircularProgress";
import Assignment from "@material-ui/icons/Assignment";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import api from "../../variables/api";
import { handleError } from "../../variables/handleError";
import moment from "moment";
import { convertToDateFromMS } from "../../variables/time";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import JobRow from "./JobRow";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
const useStyles = makeStyles(styles);

const OutstandingDetails = () => {
  const params = useParams();
  const classes = useStyles();
  const [eventdata, seteventdata] = useState([]);
  const [jobDate, setJobDate] = useState({ start: "", end: "" });
  const [eventoutstandingamount, seteventoutstandingamount] = useState(0);
  const history = useHistory();

  const getEventData = async () => {
    try {
      if (params?.id) {
        const dataEvent = await api.get(`/events/getEvent/${params?.id}`);
        const eventdetail = dataEvent.data.data.event;
        seteventdata(eventdetail);
        let jobStartDates = [];
        let jobEndDates = [];
        let isjobbooked = [];
        let outstandingamount = 0;
        eventdetail?.jobs?.map((job) => {
          job?.jobPositions?.map((jobPosition, key) => {
            jobStartDates = [...jobStartDates, jobPosition.jobStart];
            jobEndDates = [...jobEndDates, jobPosition.jobEnd];
            if (jobPosition?.booked && jobPosition?.booked?.employee) {
              isjobbooked = [...isjobbooked, true];
              outstandingamount +=
                jobPosition?.employerData?.outstandingAmount ?? 0;
            }
          });
        });
        const eventEndDate = jobEndDates.reduce(
          (previousValue, currentValue) =>
            previousValue < currentValue ? currentValue : previousValue,
          jobEndDates[0]
        );
        const eventStartdate = jobStartDates.reduce(
          (previousValue, currentValue) =>
            previousValue > currentValue ? currentValue : previousValue,
          jobStartDates[0]
        );
        const eventStart = eventStartdate
          ? moment(eventStartdate).format("dddd, DD.MM.YY")
          : "";
        const eventEnd = eventEndDate
          ? moment(eventEndDate).format("dddd, DD.MM.YY")
          : "";
        setJobDate({ start: eventStart, end: eventEnd });
        seteventoutstandingamount(outstandingamount);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getEventData();
  }, [params?.id]);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <div className="go-back">
          <span onClick={(e) => history.push("/admin/outstanding_amounts")}>
            <ArrowBackIosIcon />
            <span className="text-in">Go Back</span>
          </span>
        </div>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Outstanding Amount Details
            </h4>
          </CardHeader>
          <CardBody>
            {eventdata?.loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "35px 15px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className="form-wrapper">
                  <div>
                    <h3>
                      <strong>{eventdata.eventName}</strong>
                    </h3>
                    <p>
                      <strong>{eventdata.eventID}</strong>
                    </p>
                    {/*<p><strong> {jobDate?.start} - {jobDate?.end}</strong></p>*/}
                    <p>
                      <strong>
                        Outstanding Amount : EUR{" "}
                        {eventoutstandingamount.toFixed(2)}
                      </strong>
                    </p>
                  </div>
                </div>
                {eventdata?.jobs?.length > 0 &&
                  eventdata?.jobs?.map((job, index) => {
                    return <JobRow key={index} job={job} />;
                  })}
              </>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
export default OutstandingDetails;
