import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import GridItem from "components/Grid/GridItem.js";
import {
  getAllContracts,
  createContract,
  udpateContract,
} from "../../actions/contractAction";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import ContractRichTextEditor from "./ContractRichTextEditor.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { contractStatus } from "variables/jobs.constants";
import ContractPreviewModal from "./ContractPreviewModal";
import { TextField } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

function CreateContract({ classicModal, setClassicModal }) {
  const { data } = classicModal;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [contractError, setContractError] = useState("");

  const [contractValue, setContractValue] = useState("");
  let contractStatusLabel;

  React.useEffect(() => {
    if (contractValue !== "") {
      setContractError("");
    }
  }, [contractValue]);
  const refreshList = () => {
    dispatch(getAllContracts());
  };
  const formik = useFormik({
    initialValues: {
      name: data && data.name ? data.name : "",
      status: data?.status?.toString()?.length > 0 ? data?.status : true,
      contract: "",
      contractfile : "",
      contractHtmlfile : "",
      // active: data && data.active ? data.active : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      status: Yup.string().required("Contract Status is required"),
      // contract: Yup.string().required("Contract is required"),
    }),
    onSubmit: async (values) => {
      if (values?.contract === "") {
        setContractError("Contract is required");
        return false;
      }
      let formData = new FormData();
      let formValues;
      if (classicModal.type === "edit") {
        formValues = {
          ...values,
          // contract: values.contract.toString("markdown"),
        };
      } else {
        formValues = {
          ...values,
          // contract: values.contract.toString("markdown"),
        };
      }
      formData.append('name', values.name);
      formData.append('status', values.status);
      formData.append('contract', values.contract);
      formData.append('contractfile', values.contractfile);
      formData.append('contractHtmlfile', values.contractHtmlfile);

      // Object.entries(formValues).map((value) => {
      //   formData.append(value[0], value[1]);
      // });
      if (classicModal.type === "edit") {
        dispatch(
          udpateContract({
            id: data?._id,
            payload: formData,
            onSuccess: (res) => {
              refreshList();
            },
          })
        );
      } else {
        dispatch(
          createContract({
            payload: formData,
            onSuccess: (res) => {
              refreshList();
            },
          })
        );
      }
      setClassicModal({ ...classicModal, isVisible: false });
    },
  });

  useEffect(() => {
    data?.contract && setContractValue(data?.contract);
  }, [data?.contract]);

  return (
    <Dialog
      classes={{
        root: classes.left + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={classicModal.isVisible}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        style: {
          maxWidth: "90%",
          width: "100%",
        },
      }}
      onClose={() => setClassicModal({ ...classicModal, isVisible: false })}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      {!data && classicModal.type === "edit" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "35px 15px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() =>
                setClassicModal({ ...classicModal, isVisible: false })
              }
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Create Contract</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <form onSubmit={formik.handleSubmit} className="form">
              <GridContainer>
                <GridItem xs={6} md={6} lg={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Status
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      onChange={(e) => {
                        formik.setFieldValue("status", e.target.value);
                      }}
                      inputProps={{
                        name: "status",
                        id: "status",
                        value: formik.values.status,
                        // selected: jobBonus,
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Choose Contract Status
                      </MenuItem>
                      {contractStatus.map((item, id) => (
                        <MenuItem
                          key={item + id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.value}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.status && formik.errors.status && (
                      <div className="error-message">
                        {formik.errors.status}
                      </div>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={6} md={6} lg={6}>
                  <TextField
                    id="name"
                    label="Name"
                    fullWidth
                    value= {formik.values ? formik.values.name : ""}
                    onChange= {formik.handleChange}
                    touched= {formik.touched.name}
                    errors= {formik.errors.name}
                    onBlur= {formik.handleBlur}
                  />

                </GridItem>
                <GridItem xs={12} md={12} lg={12}>
                  <div className="form-group sign-contract-rte">
                    <label className="form-label text-left">Contract</label>
                    <ContractRichTextEditor
                      contractValue={contractValue}
                      contractID={data?.contractID}
                      setContractError={setContractError}
                      setContractValue={(e) => {
                        formik.setFieldValue("contract", e);
                        setContractValue(e);
                      }}
                      onSave={(e,contarctfile, contractHtmlfile) => {
                        formik.setFieldValue("contract", e);
                        formik.setFieldValue("contractfile", contarctfile);
                        formik.setFieldValue("contractHtmlfile",contractHtmlfile)
                        formik.handleSubmit();
                      }}
                    />
                    {/* <RichTextEditor
                      className="rich-text-editor"
                      editorClassName="rte-editor-wrapper"
                      value={contractValue}
                      name="contract"
                      onChange={(e) => {
                        formik.setFieldValue("contract", e),
                          setContractValue(e);
                      }}
                    /> */}
                    {contractError && contractError !== "" ? (
                      <div className="error-message">{contractError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </GridItem>
                <GridItem xs={12} md={12} lg={12}>
                  {/* data?.contractID && <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
                    <Button
                      type="submit"
                      Button
                      variant="contained"
                      color="primary"
                      disabled={formik.isSubmitting}
                    >
                      Submit
                    </Button>
                  </div> */}
                </GridItem>
              </GridContainer>
            </form>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

CreateContract.propTypes = {
  classicModal: PropTypes.bool,
  setClassicModal: PropTypes.func,
};

export default CreateContract;
