import moment from "moment";
import { languages } from "./user.constants";
import Geocode from 'react-geocode';
import api from "./api";

const yesterday = moment().subtract(1, "day");
export const disablePastDates = (current) => {
  return current.isAfter(yesterday);
};

const today = moment();
export const disableFutureDt = (current) => {
  return current.isBefore(today);
};

export const getLanguageName = (code) => {
  const filtered = languages.filter((l) => l.code === code);

  return filtered && filtered.length && filtered[0] ? filtered[0].name : "-";
};

export const manageStartEndTime = (userDate, jobStartDate) => {

  const time =
    typeof userDate === 'number' ? userDate : new Date(userDate).valueOf();
  const currTime = moment(Date.now())
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

  const timeDiff = time - currTime;
  const jobDateTime = moment(jobStartDate)
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);
  const changedTime = jobDateTime + timeDiff ;
  return changedTime;
};

export const getUnsignedURL = (url) => {
  const bucket = `${process.env.REACT_APP_GCS_BUCKET}/`;
  const end = "?X-";
  // const finalURL = url.match(/dsr-superstaff-assets-dev\/(.*?)??X-/);
  const finalURL = url.match(new RegExp(bucket + "(.*?)" + end));
  if (finalURL && finalURL.length === 2) {
    return finalURL[1].replace("?", "");
  } else {
    return "";
  }
};

export const getImageData = async(imagepath) => {
    const imgdata = await api.post("global-settings/getImagedata", {
      imagepath : imagepath
    })
}

export const getAddressFromLatLng = (lat, lng, onSuccess, onError) => {
  Geocode.fromLatLng(lat, lng).then(
    (response) => {
      const address = response.results[0].formatted_address;
      let city, state, country, postal_code;
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (
          let j = 0;
          j < response.results[0].address_components[i].types.length;
          j++
        ) {
          switch (response.results[0].address_components[i].types[j]) {
            case 'locality':
              city = response.results[0].address_components[i].long_name;
              break;
            case 'administrative_area_level_1':
              state = response.results[0].address_components[i].long_name;
              break;
            case 'country':
              country = response.results[0].address_components[i].long_name;
              break;
            case 'postal_code':
              postal_code = response.results[0].address_components[i].long_name;
              break;
            default:
              break;
          }
        }
      }
      onSuccess && onSuccess({ address, city, state, country, postal_code });
    },
    (error) => {
      onError && onError(error);
    },
  );
};
function padTo2Digits(num) {

    return num.toString().padStart(2, "0");
  }
export const convertMsToHM = (milliseconds) => {

    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    // let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    // 👇️ if seconds are greater than 30, round minutes up (optional)
    minutes = seconds >= 30 ? minutes + 1 : minutes;
    let hours = Math.floor(minutes / 60);

    minutes = minutes % 60;



    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    // hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
  }

export const printPriceValue = (price) => {
  return isNaN(price) ? 0 : Number(price).toFixed(2);
};

export const getTimeDifferent = (start, end) => {
  let startTime = new Date(start);
  let endTime = new Date(end);
  startTime.setSeconds(0, 0);
  endTime.setSeconds(0, 0);
  if (endTime.getTime() < startTime.getTime()) {
    endTime.setDate(startTime.getDate() + 1);
    endTime.setSeconds(0);
  }
  startTime = startTime.getTime();
  endTime = endTime.getTime();
  const diffMS = endTime - startTime;
  const diffS = diffMS / 1000;
  const diffM = diffS / 60;
  const diffH = diffM / 60;
  // console.log("diff", diffH)
  return diffH;

  // let diffH = Math.abs(end - start) / 36e5;
  // // console.log("diffh-=", diffH)
  // return diffH;
};

export const htmlDecode = (input) => {
  var e = document.createElement('div');
  e.innerHTML = input;
  if (e.childNodes.length > 0) {
    return e.childNodes[0].nodeValue;
  } else {
    return e.nodeValue;
  }
};


