import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { useSelector } from "react-redux";
import {
  manageStartEndTime,
  disablePastDates,
  getAddressFromLatLng,
  getTimeDifferent,
} from "variables/utils";
import { zipcodesMap } from "variables/zipcodes";
import api from "../../variables/api";
import { useParams } from "react-router-dom";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import Loader from "../Components/Loader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);
let newSourceEmployeeId;

function JobPositionModal({ parentFormik, classicModal, setClassicModal }) {
  const classes = useStyles();
  const [event, setEvent] = useState(null);
  const [addressline, setAddressLine] = useState(classicModal.position.jobPlace);
  const zipcodes = useSelector((state) => state?.zipcodes);
  const [pricePerHour] = useState(classicModal.position.hourlyRate);
  const [effectivePricePerHour] = React.useState(classicModal.position.employerData.hourlyRate);
  const [bonus] = useState(parentFormik.values.jobBonus);
  const { id } = useParams();
  const [employees, setEmployees] = useState([]);
  const [exchangedEmployee, setExchangedEmployee] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const dataEvent = await api.get(`/events/getEvent/${id}`);
        setEvent(dataEvent.data.data.event);
        if (booked) {
          const dataEmployees = await api.get("/employees/staff-mail");
          setEmployees(dataEmployees.data.data.employees);
        }
      } catch (error) {
        handleError(error);
      }
    })();
  }, []);

  const {
    jobPlace,
    jobDate,
    jobStart,
    jobEnd,
    jobPrice,
    jobTravelCost,
    jobTotalCost,
    hourlyRate,
    _id,
    employerData,
    showAsTeaser,
    booked,
    isConfirmed,
    confirmJobStart,
    confirmJobEnd,
  } = classicModal.position;

  const formik = useFormik({
    initialValues: {
      jobPlace,
      jobDate,
      jobStart,
      jobEnd,
      jobPrice,
      jobTravelCost,
      jobTotalCost,
      hourlyRate,
      employerData,
      showAsTeaser,
      booked,
      isConfirmed,
      confirmJobStart,
      confirmJobEnd,
    },
    validationSchema: Yup.object({
      jobPlace: Yup.string().required("Job Place is required"),
      jobDate: Yup.number()
        .typeError("Please provide valid date")
        .required("Date is required")
        .test("date", "Job date should not be before event expiration", (value, fields) => {
          const jobDate = fields.parent.jobDate;
          const eventExpirationDate = event?.eventExpirationDate;
          return !(eventExpirationDate && eventExpirationDate > jobDate);
        }),
      jobStart: Yup.string()
        .nullable(true)
        .test("start", "Please add job date first", (value, fields) => {
          return fields.parent.jobDate;
        })
        .required("Start is required"),
      confirmJobStart: Yup.string()
        .nullable(true)
        .when("jobStart", (jobStart, schema) => {
          return jobStart ? schema : schema.required("Confirmed Start is required");
        }),
      jobEnd: Yup.string()
        .nullable(true)
        .test("end", "Max. working hours (12h) exceeded", (value, fields) => {
          const startValue = fields.parent.confirmJobStart || fields.parent.jobStart;
          const endValue = fields.parent.confirmJobEnd || fields.parent.jobEnd;
          const hoursDiff = getTimeDifferent(parseInt(startValue), parseInt(endValue));

          return parseFloat(hoursDiff.toFixed(2)) <= 12 && parseFloat(hoursDiff.toFixed(2)) >= 0;
        })
        .test("same", "Start and End time should not be same", (value, fields) => {
          const startValue = fields.parent.confirmJobStart || fields.parent.jobStart;
          const endValue = fields.parent.confirmJobEnd || fields.parent.jobEnd;
          let startDate = moment(parseInt(startValue)).format("x");
          let endDate = moment(parseInt(endValue)).format("x");
          return startDate !== endDate;
        })
        .required("End is required"),
      confirmJobEnd: Yup.string()
        .nullable(true)
        .when("jobEnd", (jobEnd, schema) => {
          return jobEnd ? schema : schema.required("Confirmed End is required");
        }),
    }),
    onSubmit: async (values) => {
      const updatedJobEnd =
        values.jobStart > values.jobEnd ? parseInt(values.jobEnd) + 86400000 : values.jobEnd;
      values.jobEnd = updatedJobEnd; //So the api receives the update values
      const newJobPositions = parentFormik.values.jobPositions.map((jobPosition) => {
        if (jobPosition._id === _id) {
          return {
            ...jobPosition,
            ...values,
            jobEnd: updatedJobEnd,
            jobPrice: parseFloat(Number(values.jobPrice).toFixed(2)),
            jobTravelCost: parseFloat(Number(values.jobTravelCost).toFixed(2)),
            jobTotalCost: parseFloat(Number(values.jobTotalCost).toFixed(2)),
          };
        } else {
          return { ...jobPosition };
        }
      });
      api
        .patch(`jobs/updateJobPositionByAdmin/${_id}`, values)
        .then(async (res) => {
          await parentFormik.setFieldValue("jobPositions", newJobPositions);
          setClassicModal({ ...classicModal, isVisible: false });
          toast.success("Updated Jobposition successfully!");
        })
        .catch((e) => {
          setClassicModal({ ...classicModal, isVisible: false });
          toast.error("Something went wrong!");
        });

      const sourceEmployeeId = newSourceEmployeeId !== undefined ? newSourceEmployeeId : booked.employee._id;

      //if the staff user is changed several times without reloading, the booked.employee._id value won't update and deliver the initial employee._id
      //therefore the sourceEmployeeId is only initially set to the booked.employee_id and later overwritten with the api response data.
      //sourceEmployeeId is declared outside of the JobPositionModal function to prevent the value from being reset
      if (sourceEmployeeId === exchangedEmployee?._id || exchangedEmployee?._id === undefined) {
        console.log("Exchange not performed due to matching IDs or undefined exchangedEmployee.");
      } else {
        try {
          const res = await api.patch(
            `jobs/exchangeBookedUser/${_id}/${sourceEmployeeId}/${exchangedEmployee._id}`
          );
          toast.success("Exchange successful!");
          newSourceEmployeeId = res.data.targetEmployeeId;
        } catch (e) {
          console.error(e);
          toast.error("Something went wrong with the API request!");
        }
      }
    },
  });

  useEffect(() => {
    const startdate = moment(parseInt(formik.values.jobStart)).get("date"),
      startmonth = moment(parseInt(formik.values.jobStart)).get("M"),
      startyear = moment(parseInt(formik.values.jobStart)).get("year");
    const enddate = moment(parseInt(formik.values.jobEnd)).get("date"),
      endmonth = moment(parseInt(formik.values.jobEnd)).get("M"),
      endyear = moment(parseInt(formik.values.jobEnd)).get("year");

    let hours = 4;
    let jobstarttime = moment(parseInt(formik.values.jobStart))
      .set("date", startdate)
      .set("month", startmonth)
      .set("year", startyear)
      .format("x");
    let jobEndtime = moment(parseInt(formik.values.jobEnd))
      .set("date", enddate)
      .set("month", endmonth)
      .set("year", endyear)
      .format("x");
    formik.setFieldValue(
      "jobStart",
      moment(parseInt(formik.values.jobStart))
        .set("date", startdate)
        .set("month", startmonth)
        .set("year", startyear)
        .format("x")
    );
    formik.setFieldValue(
      "jobEnd",
      moment(parseInt(formik.values.jobEnd))
        .set("date", enddate)
        .set("month", endmonth)
        .set("year", endyear)
        .format("x")
    );

    formik?.validateForm();

    if (jobEndtime >= jobstarttime) {
      hours = getTimeDifferent(parseInt(jobstarttime), parseInt(jobEndtime));
    } else {
      jobEndtime = parseInt(jobEndtime) + 86400000;
      hours = getTimeDifferent(parseInt(jobstarttime), jobEndtime);
    }
    hours = hours < 4 ? 4 : hours;
    const price = Number(
      hours * pricePerHour + (bonus && bonus > 0 ? hours * pricePerHour * (bonus / 100) : 0)
    ).toFixed(2);
    const effectivePrice = Number(
      hours * effectivePricePerHour + (bonus && bonus > 0 ? hours * effectivePricePerHour * (bonus / 100) : 0)
    ).toFixed(2);
    const formikjobeffectivePrice = effectivePrice ? effectivePrice : price;
    formik.setFieldValue("jobPrice", price);
    formik.setFieldValue("employerData.jobPrice", formikjobeffectivePrice);
    const jobetotalcost = (
      parseFloat(Number(formik.values.jobPrice).toFixed(2)) +
      parseFloat(Number(formik.values.jobTravelCost ? formik.values.jobTravelCost : 0).toFixed(2))
    ).toFixed(2);
    const jobeffectivetotalcost = (
      parseFloat(Number(formik.values.employerData.jobPrice).toFixed(2)) +
      parseFloat(
        Number(
          formik.values.employerData.jobTravelCost ? formik.values.employerData.jobTravelCost : 0
        ).toFixed(2)
      )
    ).toFixed(2);
    formik.setFieldValue("jobTotalCost", jobetotalcost);
    formik.setFieldValue("employerData.jobTotalCost", jobeffectivetotalcost);
    formik.setFieldValue("hourlyRate", pricePerHour);
    formik.setFieldValue("employerData.hourlyRate", effectivePricePerHour);
  }, [formik.values]);

  useEffect(() => {
    if (addressline === "") {
      formik?.setFieldValue("jobPlace", "");
    } else {
      formik?.validateForm();
    }
  }, [addressline]);
  const renderSuggestionItem = (suggestion, type) => {
    if (type === "sublocality") {
      return suggestion?.formattedSuggestion?.mainText;
    } else if (type === "locality") {
      return suggestion?.formattedSuggestion?.mainText;
    } else {
      return suggestion.description;
    }
  };

  const handleError = (name, error) => {
    formik?.setFieldError(name, error);
  };

  const setAddressToState = (address, res, rLatLang, zipcode, selectedZip) => {
    let returnAddress = address;
    const region = {
      value: returnAddress,
      state: zipcode?.[0]?.state,
      city: zipcode?.[0]?.city,
      zipcode: zipcode?.[0]?.zipcode,
      address: res[0].address_components[0].long_name,
      coordinates: [rLatLang?.lat, rLatLang?.lng],
    };
    setAddressLine(returnAddress);
    formik?.setFieldValue("jobPlace", returnAddress);
    formik?.setFieldValue("jobPlaceAddress", region);
    if (selectedZip) {
      formik?.setFieldValue(
        "employerData.jobTravelCost",
        Number(selectedZip?.effectiveTravelCost ? selectedZip?.effectiveTravelCost : 0).toFixed(2)
      );
      formik?.setFieldValue(
        "jobTravelCost",
        Number(selectedZip?.Reisekosten ? selectedZip?.Reisekosten : 0).toFixed(2)
      );
    }
  };

  const handleEmailChange = (event) => {
    const selectedEmail = event.target.value;
    const selectedEmployee = employees.find((employee) => employee.email === selectedEmail);
    setExchangedEmployee(selectedEmployee);

    formik.setFieldValue("booked.employee.email", selectedEmail);
    formik.setFieldValue("booked.employee.name", selectedEmployee ? selectedEmployee.name : "");
  };

  const handleSelect = async (address, name, type, suggestion) => {
    try {
      const geocodRes = await geocodeByAddress(address);
      const rLatLang = {
        lat: geocodRes?.[0]?.geometry?.location?.lat(),
        lng: geocodRes?.[0]?.geometry?.location?.lng(),
      };
      await getAddressFromLatLng(
        rLatLang?.lat,
        rLatLang?.lng,
        (res) => {
          let filterZipcodes = [];
          let selectedZip = false;
          if (res?.postal_code) {
            selectedZip = zipcodes?.zipcodeList?.find((zip) => {
              return String(zip.PLZ) === String(res?.postal_code);
            });
            filterZipcodes = zipcodesMap.filter((z) => {
              const foundIndex = z.zipcodes.indexOf(Number(res?.postal_code));
              return foundIndex !== -1;
            });
          } else if (res?.state) {
            filterZipcodes = [{ state: res?.state }];
          }
          setAddressToState(
            address,
            geocodRes,
            rLatLang,
            [{ state: filterZipcodes?.[0]?.state, zipcode: res?.postal_code, city: res?.city }],
            selectedZip
          );
        },
        (err) => {
          setAddressToState(address, geocodRes, rLatLang, []);
        }
      );
    } catch (error) {
      formik?.setFieldError(name, error);
    }
  };

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={classicModal.isVisible}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        style: {
          maxWidth: "500px",
          width: "100%",
        },
      }}
      onClose={() => setClassicModal({ ...classicModal, isVisible: false })}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
        <Button
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="default"
          onClick={() => setClassicModal({ ...classicModal, isVisible: false })}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Update Job Data</h4>
      </DialogTitle>
      <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
        <form onSubmit={formik.handleSubmit} className="job-position-form">
          <GridContainer>
            <GridItem xs={12} lg={12}>
              <PlacesAutocomplete
                name={"jobPlace"}
                id={"jobPlace"}
                // searchOptions={['address']}
                value={addressline}
                onChange={(value) => setAddressLine(value)}
                onSelect={(data, placeid, suggestion) => {
                  handleSelect(data, "jobPlace", "sublocality", suggestion);
                }}
                onError={(error) => handleError("jobPlace", error)}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div className="autocomplete-dropdown-wrap">
                    <CustomInput
                      labelText="Job Place"
                      id="jobPlace"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        name: "jobPlace",
                        value: addressline,
                        ...getInputProps({
                          placeholder: "job Place",
                          className: "location-search-input form-control",
                        }),
                      }}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <Loader />}
                      {suggestions.map((suggestion, key) => {
                        // if (!suggestion?.types?.includes('locality')) {
                        const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                        return (
                          <div
                            key={key}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                          >
                            <span>
                              {renderSuggestionItem(suggestion, "sublocality")}
                              <small style={{ color: "gray" }}>
                                {suggestion?.formattedSuggestion?.mainText
                                  ? suggestion?.description?.replace(
                                      suggestion?.formattedSuggestion?.mainText,
                                      ""
                                    )
                                  : suggestion?.description}
                              </small>
                            </span>
                          </div>
                        );
                        // }
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </GridItem>
            <GridItem xs={12} lg={12}>
              <InputLabel className={classes.label}>job Date</InputLabel>
              <FormControl fullWidth>
                <Datetime
                  timeFormat={false}
                  dateFormat="DD-MM-YYYY"
                  initialValue={formik.values.jobDate}
                  closeOnSelect={true}
                  inputProps={{ name: "jobDate", placeholder: "Date", readOnly: true }}
                  onChange={(e) => {
                    var d = e._d;
                    const time = new Date(d).valueOf();
                    formik.setFieldValue("jobDate", time);
                    formik.setFieldValue("jobStart", time);
                    formik.setFieldValue("jobEnd", time);
                  }}
                />
              </FormControl>
              {formik.touched.jobDate && formik.errors.jobDate ? (
                <FormHelperText
                  id={"1-text"}
                  style={{
                    color: "red",
                    fontWeight: 300,
                    fontSize: "12px",
                    textAlign: "left",
                  }}
                >
                  {formik.errors.jobDate}
                </FormHelperText>
              ) : null}
            </GridItem>
            <GridItem xs={6} lg={6}>
              <InputLabel className={classes.label}>Job Start Time</InputLabel>
              <FormControl fullWidth>
                <Datetime
                  isValidDate={disablePastDates}
                  dateFormat={false}
                  initialValue={
                    formik.values.confirmJobStart
                      ? moment(parseInt(formik.values.confirmJobStart)).format("HH:mm")
                      : formik.values.jobStart
                      ? moment(parseInt(formik.values.jobStart)).format("HH:mm")
                      : ""
                  }
                  value={
                    formik.values.confirmJobStart
                      ? moment(parseInt(formik.values.confirmJobStart)).format("HH:mm")
                      : formik.values.jobStart
                      ? moment(parseInt(formik.values.jobStart)).format("HH:mm")
                      : ""
                  }
                  timeFormat="HH:mm"
                  timeConstraints={{ minutes: { step: 15 } }}
                  inputProps={{
                    name: "confirmJobStart",
                    placeholder: "Job Start Time",
                    readOnly: true,
                  }}
                  onChange={(e) => {
                    var d = e._d;
                    const changedTime = manageStartEndTime(d, formik.values.jobDate);
                    if (formik.values.confirmJobStart) {
                      formik.setFieldValue("confirmJobStart", changedTime);
                    } else {
                      formik.setFieldValue("jobStart", changedTime);
                    }
                  }}
                />
                {formik.errors.jobStart ? (
                  <p
                    className="MuiFormHelperText-root"
                    style={{ color: "red", fontWeight: 300, fontSize: "12px", textAlign: "left" }}
                  >
                    {formik.errors.jobStart}
                  </p>
                ) : (
                  ""
                )}
              </FormControl>
            </GridItem>
            <GridItem xs={6} lg={6}>
              <InputLabel className={classes.label}>Job End Time</InputLabel>
              <FormControl fullWidth>
                <Datetime
                  isValidDate={disablePastDates}
                  dateFormat={false}
                  initialValue={
                    formik.values.confirmJobEnd
                      ? moment(parseInt(formik.values.confirmJobEnd)).format("HH:mm")
                      : formik.values.jobEnd
                      ? moment(parseInt(formik.values.jobEnd)).format("HH:mm")
                      : ""
                  }
                  value={
                    formik.values.confirmJobEnd
                      ? moment(parseInt(formik.values.confirmJobEnd)).format("HH:mm")
                      : formik.values.jobEnd
                      ? moment(parseInt(formik.values.jobEnd)).format("HH:mm")
                      : ""
                  }
                  timeFormat="HH:mm"
                  timeConstraints={{ minutes: { step: 15 } }}
                  inputProps={{
                    name: "confirmJobEnd",
                    placeholder: "Job End Time",
                    readOnly: true,
                  }}
                  onChange={(e) => {
                    var d = e._d;
                    const changedTime = manageStartEndTime(d, formik.values.jobDate);
                    if (formik.values.confirmJobEnd) {
                      formik.setFieldValue("confirmJobEnd", changedTime);
                    } else {
                      formik.setFieldValue("jobEnd", changedTime);
                    }
                  }}
                />
                {formik.errors.jobEnd ? (
                  <p
                    className="MuiFormHelperText-root"
                    style={{ color: "red", fontWeight: 300, fontSize: "12px", textAlign: "left" }}
                  >
                    {formik.errors.jobEnd}
                  </p>
                ) : (
                  ""
                )}
              </FormControl>
            </GridItem>
            <GridItem xs={4} lg={4}>
              <CustomInput
                labelText="Job Price"
                id="jobPrice"
                formControlProps={{
                  fullWidth: true,
                }}
                disabled={true}
                inputProps={{
                  type: "text",
                  name: "jobPrice",
                  value: formik.values?.employerData?.jobPrice,
                  onChange: formik.handleChange,
                  touched: formik.touched?.employerData?.jobPrice,
                  errors: formik.errors?.employerData?.jobPrice,
                  onBlur: formik.handleBlur,
                }}
              />
            </GridItem>
            <GridItem xs={4} lg={4}>
              <CustomInput
                labelText="Job Travel Cost"
                id="jobTravelCost"
                formControlProps={{
                  fullWidth: true,
                }}
                disabled={true}
                inputProps={{
                  type: "text",
                  name: "jobTravelCost",
                  value: formik.values?.employerData?.jobTravelCost
                    ? formik.values?.employerData?.jobTravelCost
                    : 0,
                  onChange: formik.handleChange,
                  touched: formik.touched?.employerData?.jobTravelCost,
                  errors: formik.errors?.employerData?.jobTravelCost,
                  onBlur: formik.handleBlur,
                }}
              />
            </GridItem>
            <GridItem xs={4} lg={4}>
              <CustomInput
                labelText="Job Total Cost"
                id="jobTotalCost"
                formControlProps={{
                  fullWidth: true,
                }}
                disabled={true}
                inputProps={{
                  type: "text",
                  name: "jobTotalCost",
                  value: formik.values?.employerData?.jobTotalCost,
                  onChange: formik.handleChange,
                  touched: formik.touched?.employerData?.jobTotalCost,
                  errors: formik.errors?.employerData?.jobTotalCost,
                  onBlur: formik.handleBlur,
                }}
              />
            </GridItem>
            {formik.values?.booked && (
              <>
                <GridItem xs={12} lg={12}>
                  <CustomInput
                    labelText="Booked User"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "booked.employee.name",
                      value: formik.values?.booked?.employee?.name || "",
                      touched: formik.touched?.booked?.employee?.name,
                      errors: formik.errors?.booked?.employee?.name,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} lg={12}>
                  <FormControl fullWidth>
                    <InputLabel id="email-label">Email</InputLabel>
                    <Select
                      labelId="email-label"
                      id="email"
                      name="booked.employee.email"
                      value={formik.values?.booked?.employee?.email || ""}
                      onChange={handleEmailChange}
                    >
                      {employees
                        .filter((employee) => employee.email)
                        .sort((a, b) => a.email.localeCompare(b.email))
                        .map((employee) => (
                          <MenuItem key={employee._id} value={employee.email}>
                            {employee.email}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </>
            )}
            <GridItem xs={12} lg={12}>
              <div className="d-flex align-items-center">
                <FormControlLabel
                  required
                  name="showAsTeaser"
                  checked={formik.values.showAsTeaser}
                  control={<Checkbox color="primary" />}
                  onChange={formik.handleChange}
                  label="Show Position as Teaser"
                />
              </div>
            </GridItem>

            <GridItem xs={12} lg={12}>
              <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
                <Button
                  type="button"
                  onClick={formik.handleSubmit}
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                >
                  Update Job Position
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </form>
      </DialogContent>
    </Dialog>
  );
}

JobPositionModal.propTypes = {
  parentFormik: PropTypes.any,
  classicModal: PropTypes.any,
  setClassicModal: PropTypes.func,
};

export default JobPositionModal;
