import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Geocode from "react-geocode";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLogin = localStorage.getItem("token");
  const location = useLocation();
  React.useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
  }, []);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLogin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: {
                lastLocation: location.pathname,
              },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
