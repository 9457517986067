import { settingConstants } from "./types";
import axios from "axios";
import { toast } from "react-toastify";
import api from "../variables/api";

export const getSettings = () => {
  const request = () => {
    return { type: settingConstants.GET_SETTINGS_REQUEST };
  };
  const success = (settings) => {
    return { type: settingConstants.GET_SETTINGS_SUCCESS, settings };
  };
  const failure = (error) => {
    return { type: settingConstants.GET_SETTINGS_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const settings = await api.get("/global-settings");
      dispatch(success(settings.data.settings[0]));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong!"));
        toast.error("Something went wrong!");
      }
    }
  };
};

export const updateSettings = ({ id, payload }) => {
  const request = () => {
    return { type: settingConstants.UPDATE_SETTINGS_REQUEST };
  };
  const success = () => {
    return { type: settingConstants.UPDATE_SETTINGS_SUCCESS };
  };
  const failure = (error) => {
    return { type: settingConstants.UPDATE_SETTINGS_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      await api.patch(`/global-settings?${id}`, payload);
        toast.success("Updated Successfully!");
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};
