import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import moment from "moment";
import {useFormik} from "formik";
import * as Yup from "yup";
import {makeStyles} from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import {useSelector, useDispatch} from 'react-redux';
import {getSettings} from "../../actions/settingsAction";

import {manageStartEndTime, disablePastDates, getTimeDifferent} from "variables/utils";
import api from "../../variables/api";

import {useParams} from "react-router-dom";

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import Loader from '../Components/Loader';
import {getAddressFromLatLng} from "variables/utils";
import {zipcodesMap} from 'variables/zipcodes';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {toast} from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

function JobPositionModal({parentFormik, classicModal, setClassicModal, jobId}) {

    const classes = useStyles();
    const [event, setEvent] = useState(null);
    const formikFormRef = useRef();
    const [addressline, setAddressLine] = useState("");
    const [pricePerHour, setPricePerHour] = React.useState(0);
    const [effectivePricePerHour, setEffectivePricePerHour] = React.useState(0);
    const [bonus, setBonus] = useState(parentFormik.values.jobBonus);
    const {id} = useParams();
    const staffTypes = useSelector((state) => state?.settings?.data?.staffTypes);
    const zipcodes = useSelector((state) => state?.zipcodes);
    const dispatch = useDispatch();


    useEffect(() => {
        (async () => {
            try {
                // setLoading(true);
                const dataEvent = await api.get(`/events/getEvent/${id}`);
                setEvent(dataEvent.data.data.event);
                // setLoading(false);
            } catch (error) {
                // setLoading(false);
                handleError(error);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (staffTypes && staffTypes.length) {
                    const currentStaffType = staffTypes?.find((s) => s.name === parentFormik.values.jobName);
                    setPricePerHour(currentStaffType?.pricePerHour);
                    setEffectivePricePerHour(currentStaffType?.effectivePricePerHour);
                } else {
                    await dispatch(getSettings()).then(() => {
                        const currentStaffType = staffTypes?.find((s) => s.name === parentFormik.values.jobName);
                        setPricePerHour(currentStaffType?.pricePerHour);
                        setEffectivePricePerHour(currentStaffType?.effectivePricePerHour);
                    });
                }
            } catch (error) {
                handleError(error);
            }
        })();
    }, [effectivePricePerHour]);

    const formik = useFormik({
        initialValues: {
            jobPlace: "",
            jobDate: moment().startOf('day').valueOf(),
            // jobDate: moment(new Date()).format("DD-MM-YYYY"),
            // jobStart: todate.setHours(0, 0, 0, 0),
            // jobEnd: todate.setHours(0, 0, 0, 0),
            jobStart: moment().startOf('day').valueOf(),
            jobEnd: moment().startOf('day').valueOf(),
            hourlyRate: 0,
            jobPrice: 0,
            jobTravelCost: 0,
            jobTotalCost: 0,
            employerData: {
                jobPrice: 0,
                jobTravelCost: 0,
                jobTotalCost: 0,
                hourlyRate: 0
            },
            showAsTeaser: false,
        },
        validationSchema: Yup.object({
            // jobName: Yup.string().required("Job name is required"),
            jobPlace: Yup.string().required("Job Place is required"),
            // jobDate: Yup.number().required("Job date is required"),
            // jobStart: Yup.string().required("Job start time is required"),
            // jobEnd: Yup.string().required("Job end time is required"),
            jobDate: Yup.number()
                .typeError("Please provide valid date")
                .required("Date is required")
                .test(
                    "date",
                    "Job date should not be before event expiration",
                    (value, fields) => {
                        const jobDate = fields.parent.jobDate;
                        if (event?.eventExpirationDate && event?.eventExpirationDate > jobDate) {
                            return false;
                        } else {
                            return true;
                        }
                        // if false then error visible
                    }
                ),

            jobStart: Yup.string().nullable(true)
                .test("start", "Please add job date first", (value, fields) => {
                    if (!fields.parent.jobDate) {
                        return false;
                    } else {
                        return true;
                    }
                })
                .required("Start is required")
                .test("start", "Enter only future timings", (value, fields) => {
                    var startJobdate = moment(fields.parent.jobDate)
                        .set("hour", moment(Number(value)).get("hour"))
                        .set("minute", moment(Number(value)).get("minute"));
                    return startJobdate < new Date().valueOf() ? false : true;

                }),
            jobEnd: Yup.string().nullable(true)

                .test("end", "Max. working hours (12h) exceeded", (value, fields) => {
                    const hoursDiff = getTimeDifferent(
                        parseInt(fields.parent.jobStart),
                        parseInt(fields.parent.jobEnd),
                    );

                    return (parseFloat(hoursDiff.toFixed(2)) <= 12 && parseFloat(hoursDiff.toFixed(2)) >= 0);
                })
                .test('same', "Start and End time should not be same", (value, fields) => {
                    let startDate =
                        moment(parseInt(fields.parent.jobStart)).format('x');
                    ;
                    let endDate =
                        moment(parseInt(fields.parent.jobEnd)).format('x');
                    ;

                    if (
                        startDate ===
                        endDate) {
                        return false;
                    } else {
                        return true;
                    }
                })
                .required("End is required"),

        }),
        onSubmit: async (values) => {
            if (values.jobStart > values.jobEnd) {
                values.jobEnd = parseInt(values.jobEnd) + 86400000;
            }
            await api.patch(`jobs/updateJob/${jobId}`, {
                newPositions: [{
                    ...values,
                    jobPrice: parseFloat(Number(values.jobPrice).toFixed(2)),
                    jobTravelCost: parseFloat(Number(values.jobTravelCost).toFixed(2)),
                    jobTotalCost: parseFloat(Number(values.jobTotalCost).toFixed(2)),
                }]
            }).then(async res => {
                await parentFormik.setFieldValue("jobPositions", res.data.data.job.jobPositions);
                setClassicModal({...classicModal, isVisible: false});
                toast.success('Jobposition added successfully!');
            }).catch(e => {
                setClassicModal({...classicModal, isVisible: false});
                toast.error('Something went wrong!');
            });

        },
    });

    useEffect(async () => {

        const startdate = moment(parseInt(formik.values.jobStart)).get('date'),
            startmonth = moment(parseInt(formik.values.jobStart)).get('M'),
            startyear = moment(parseInt(formik.values.jobStart)).get('year');
        const enddate = moment(parseInt(formik.values.jobEnd)).get('date'),
            endmonth = moment(parseInt(formik.values.jobEnd)).get('M'),
            endyear = moment(parseInt(formik.values.jobEnd)).get('year');
        // console.log("date", formik.values.jobDate, "start", formik.values.jobStart, "end", formik.values.jobEnd)
        let hours = 4;
        let jobstarttime = moment(parseInt(formik.values.jobStart)).set('date', startdate).set('month', startmonth).set('year', startyear).format('x');
        let jobEndtime = moment(parseInt(formik.values.jobEnd)).set('date', enddate).set('month', endmonth).set('year', endyear).format('x');
        formik.setFieldValue("jobStart", moment(parseInt(formik.values.jobStart)).set('date', startdate).set('month', startmonth).set('year', startyear).format('x'));
        formik.setFieldValue("jobEnd", moment(parseInt(formik.values.jobEnd)).set('date', enddate).set('month', endmonth).set('year', endyear).format('x'));
        formik?.validateForm();

        if (jobEndtime >= jobstarttime) {
            hours = getTimeDifferent(parseInt(jobstarttime), parseInt(jobEndtime));
        } else {
            jobEndtime = parseInt(jobEndtime) + 86400000;
            hours = getTimeDifferent(parseInt(jobstarttime), jobEndtime);
        }
        hours = hours < 4 ? 4 : hours;
        const price = Number(
            hours * pricePerHour +
            (bonus && bonus > 0 ? hours * pricePerHour * (bonus / 100) : 0),
        ).toFixed(2);
        const effectivePrice = Number(
            hours * effectivePricePerHour +
            (bonus && bonus > 0
                ? hours * effectivePricePerHour * (bonus / 100)
                : 0),
        ).toFixed(2);
        const formikjobeffectivePrice = effectivePrice ? effectivePrice : price;
        formik.setFieldValue("jobPrice", price);
        formik.setFieldValue('employerData.jobPrice', formikjobeffectivePrice);
        const jobetotalcost = (parseFloat(Number(formik.values.jobPrice).toFixed(2)) + parseFloat(Number(formik.values.jobTravelCost ? formik.values.jobTravelCost : 0).toFixed(2))).toFixed(2);
        const jobeffectivetotalcost = (parseFloat(Number(formik.values.employerData.jobPrice).toFixed(2)) + parseFloat(Number(formik.values.employerData.jobTravelCost ? formik.values.employerData.jobTravelCost : 0).toFixed(2))).toFixed(2);
        formik.setFieldValue("jobTotalCost", jobetotalcost);
        formik.setFieldValue('employerData.jobTotalCost', jobeffectivetotalcost);
        formik.setFieldValue('hourlyRate', pricePerHour);
        formik.setFieldValue('employerData.hourlyRate', effectivePricePerHour);
    }, [formik.values, effectivePricePerHour]);

    useEffect(() => {
        if (addressline === '') {
            formik?.setFieldValue('jobPlace', '');
        } else {
            formik?.validateForm();
        }
    }, [addressline]);

    const renderSuggestionItem = (suggestion, type) => {
        if (type === 'sublocality') {
            return suggestion?.formattedSuggestion?.mainText;
        } else if (type === 'locality') {
            return suggestion?.formattedSuggestion?.mainText;
        } else {
            return suggestion.description;
        }
    };

    const handleError = (name, error) => {
        formik?.setFieldError(name, error);
    };

    const setAddressToState = (address, res, rLatLang, zipcode, selectedZip) => {
        let returnAddress = address;
        const region = {
            value: returnAddress,
            state: zipcode?.[0]?.state,
            city: zipcode?.[0]?.city,
            zipcode: zipcode?.[0]?.zipcode,
            address: res[0].address_components[0].long_name,
            coordinates: [rLatLang?.lat, rLatLang?.lng],
        };
        setAddressLine(returnAddress);
        formik?.setFieldValue('jobPlace', returnAddress)
        formik?.setFieldValue('jobPlaceAddress', region);
        if (selectedZip) {

            formik?.setFieldValue('employerData.jobTravelCost', Number(selectedZip?.effectiveTravelCost
                ? selectedZip?.effectiveTravelCost
                : 0).toFixed(2))
            formik?.setFieldValue('jobTravelCost', Number(selectedZip?.Reisekosten
                ? selectedZip?.Reisekosten
                : 0).toFixed(2))
        }
    };

    const handleSelect = async (address, name, type, suggestion) => {
        try {
            const geocodRes = await geocodeByAddress(address);
            const rLatLang = {
                lat: geocodRes?.[0]?.geometry?.location?.lat(),
                lng: geocodRes?.[0]?.geometry?.location?.lng(),
            };
            await getAddressFromLatLng(
                rLatLang?.lat,
                rLatLang?.lng,
                (res) => {
                    let filterZipcodes = [];
                    let selectedZip = false;
                    if (res?.postal_code) {
                        selectedZip = zipcodes?.zipcodeList?.find((zip) => {
                            return String(zip.PLZ) === String(res?.postal_code);
                        });
                        filterZipcodes = zipcodesMap.filter((z) => {
                            const foundIndex = z.zipcodes.indexOf(Number(res?.postal_code));
                            return foundIndex !== -1;
                        });
                    } else if (res?.state) {
                        filterZipcodes = [{state: res?.state}];
                    }
                    setAddressToState(address, geocodRes, rLatLang, [
                        {state: filterZipcodes?.[0]?.state, zipcode: res?.postal_code, city: res?.city,},
                    ], selectedZip,);
                },
                (err) => {
                    setAddressToState(address, geocodRes, rLatLang, []);
                },
            );
        } catch (error) {
            formik?.setFieldError(name, error);
        }
    };

    const checkDecimal = (value, field) => {
        formik.setFieldValue(field, value);
        formik.handleChange;
    }

    return (
        <Dialog
            classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
            }}
            open={classicModal.isVisible}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
                style: {
                    maxWidth: "500px",
                    width: "100%",
                },
            }}
            onClose={() => setClassicModal({...classicModal, isVisible: false})}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
        >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => setClassicModal({...classicModal, isVisible: false})}
                >
                    <Close className={classes.modalClose}/>
                </Button>
                <h4 className={classes.modalTitle}>Add Job Position for - <b>{parentFormik.values.jobName}</b> - job
                </h4>

            </DialogTitle>
            <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
            >
                <form onSubmit={formik.handleSubmit} className="job-position-form">
                    <GridContainer>
                        <GridItem xs={12} lg={12}>
                            <PlacesAutocomplete
                                name={'jobPlace'}
                                id={'jobPlace'}
                                // searchOptions={['address']}
                                value={addressline}
                                onChange={(value) => setAddressLine(value)}
                                onSelect={(data, placeid, suggestion) => {
                                    handleSelect(
                                        data,
                                        'jobPlace',
                                        'sublocality',
                                        suggestion,
                                    )
                                }
                                }
                                onError={(error) => handleError('jobPlace', error)}>
                                {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading,
                                  }) => (
                                    <div className="autocomplete-dropdown-wrap">
                                        <CustomInput
                                            labelText="Job Place"
                                            id="jobPlace"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                type: "text",
                                                name: "jobPlace",
                                                value: addressline,
                                                ...getInputProps({
                                                    placeholder: 'job Place',
                                                    className: 'location-search-input form-control',
                                                })
                                            }}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <Loader/>}
                                            {suggestions.map((suggestion, key) => {
                                                // if (!suggestion?.types?.includes('locality')) {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                return (
                                                    <div
                                                        key={key}
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                        })}>
                            <span>
                              {renderSuggestionItem(
                                  suggestion,
                                  'sublocality',
                              )}
                                <small style={{color: 'gray'}}>
                                {suggestion?.formattedSuggestion
                                    ?.mainText
                                    ? suggestion?.description?.replace(
                                        suggestion?.formattedSuggestion
                                            ?.mainText,
                                        '',
                                    )
                                    : suggestion?.description}
                              </small>
                            </span>
                                                    </div>
                                                );
                                                // }
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </GridItem>
                        <GridItem xs={12} lg={12}>
                            <InputLabel className={classes.label}>Job Date</InputLabel>
                            <FormControl fullWidth>
                                <Datetime
                                    // isValidDate={disablePastDates}
                                    timeFormat={false}
                                    dateFormat="DD-MM-YYYY"
                                    initialValue={formik.values.jobDate}
                                    closeOnSelect={true}

                                    inputProps={{name: "jobDate", placeholder: "Date", readOnly: true}}
                                    onChange={(e) => {
                                        var d = e._d;
                                        const time = new Date(d).valueOf();
                                        formik.setFieldValue("jobDate", time);
                                        formik.setFieldValue("jobStart", time);
                                        formik.setFieldValue("jobEnd", time);
                                    }}
                                />
                            </FormControl>
                            {/* {formik.touched.jobDate && formik.errors.jobDate && (
                <div className="error-message">{formik.errors.jobDate}</div>
              )} */}
                            {formik.touched.jobDate && formik.errors.jobDate ? (
                                <FormHelperText
                                    id={"1-text"}
                                    style={{
                                        color: "red", fontWeight: 300, fontSize: "12px", textAlign: "left"
                                    }}
                                >
                                    {formik.errors.jobDate}
                                </FormHelperText>
                            ) : null}
                        </GridItem>
                        <GridItem xs={6} lg={6}>
                            <InputLabel className={classes.label}>Job Start Time</InputLabel>
                            <FormControl fullWidth>
                                <Datetime
                                    isValidDate={disablePastDates}
                                    dateFormat={false}
                                    initialValue={formik.values.jobStart ? moment(parseInt(formik.values.jobStart)).format(
                                        "HH:mm"
                                    ) : ''}
                                    value={formik.values.jobStart ? moment(parseInt(formik.values.jobStart)).format(
                                        "HH:mm"
                                    ) : ''}
                                    timeFormat="HH:mm"

                                    timeConstraints={{minutes: {step: 15}}}
                                    inputProps={{name: "jobStart", placeholder: "Job Start Time", readOnly: true}}
                                    onChange={(e) => {
                                        var d = e._d;
                                        // const time = new Date(d).valueOf();
                                        const changedTime = manageStartEndTime(
                                            d,
                                            formik.values.jobDate
                                        );
                                        formik.setFieldValue("jobStart", changedTime);
                                    }}
                                />
                                {formik.errors.jobStart ? (
                                    <p
                                        className="MuiFormHelperText-root"
                                        style={{color: "red", fontWeight: 300, fontSize: "12px", textAlign: "left"}}
                                    >
                                        {formik.errors.jobStart}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </FormControl>
                        </GridItem>
                        <GridItem xs={6} lg={6}>
                            <InputLabel className={classes.label}>Job End Time</InputLabel>
                            <FormControl fullWidth>
                                <Datetime
                                    isValidDate={disablePastDates}
                                    dateFormat={false}
                                    initialValue={formik.values.jobEnd ? moment(parseInt(formik.values.jobEnd)).format(
                                        "HH:mm"
                                    ) : ''}
                                    value={formik.values.jobEnd ? moment(parseInt(formik.values.jobEnd)).format(
                                        "HH:mm"
                                    ) : ''}
                                    timeFormat="HH:mm"
                                    timeConstraints={{minutes: {step: 15}}}
                                    inputProps={{name: "jobEnd", placeholder: "Job End Time", readOnly: true}}
                                    onChange={(e) => {
                                        var d = e._d;
                                        const changedTime = manageStartEndTime(
                                            d,
                                            formik.values.jobDate
                                        );
                                        // const time = new Date(d).valueOf();
                                        formik.setFieldValue("jobEnd", changedTime);
                                    }}
                                />
                                {formik.errors.jobEnd ? (
                                    <p
                                        className="MuiFormHelperText-root"
                                        style={{color: "red", fontWeight: 300, fontSize: "12px", textAlign: "left"}}
                                    >
                                        {formik.errors.jobEnd}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </FormControl>
                        </GridItem>
                        <GridItem xs={4} lg={4}>
                            <CustomInput
                                labelText="Job Price"
                                id="jobPrice"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                disabled={true}
                                inputProps={{
                                    type: "text",
                                    name: "jobPrice",
                                    value: formik.values?.employerData?.jobPrice,
                                    onChange: formik.handleChange,
                                    touched: formik.touched?.employerData?.jobPrice,
                                    errors: formik.errors?.employerData?.jobPrice,
                                    onBlur: formik.handleBlur,
                                }}
                            />

                        </GridItem>
                        <GridItem xs={4} lg={4}>
                            <CustomInput
                                labelText="Job Travel Cost"
                                id="jobTravelCost"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                disabled={true}
                                inputProps={{
                                    type: "text",
                                    name: "jobTravelCost",
                                    value: formik.values?.employerData?.jobTravelCost ? formik.values?.employerData?.jobTravelCost : 0,
                                    onChange: formik.handleChange,
                                    touched: formik.touched?.employerData?.jobTravelCost,
                                    errors: formik.errors?.employerData?.jobTravelCost,
                                    onBlur: formik.handleBlur,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={4} lg={4}>
                            <CustomInput
                                labelText="Job Total Cost"
                                id="jobTotalCost"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                disabled={true}
                                inputProps={{
                                    type: "text",
                                    name: "jobTotalCost",
                                    value: formik.values?.employerData?.jobTotalCost,
                                    onChange: formik.handleChange,
                                    touched: formik.touched?.employerData?.jobTotalCost,
                                    errors: formik.errors?.employerData?.jobTotalCost,
                                    onBlur: formik.handleBlur,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} lg={12}>
                            <div className="d-flex align-items-center">
                                <FormControlLabel
                                    required
                                    name="showAsTeaser"
                                    checked={formik.values.showAsTeaser}
                                    control={<Checkbox color="primary"/>}
                                    onChange={formik.handleChange}
                                    label="Show Position as Teaser" />
                            </div>
                        </GridItem>
                        <GridItem xs={12} lg={12}>
                            <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
                                <Button
                                    type="button"
                                    onClick={formik.handleSubmit}
                                    variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                >
                  Add Job Position
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </form>
      </DialogContent>
    </Dialog>
  );
}

JobPositionModal.propTypes = {
    parentFormik: PropTypes.any,
    classicModal: PropTypes.any,
    setClassicModal: PropTypes.func,
    jobId: PropTypes.any,
};

export default JobPositionModal;
