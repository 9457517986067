import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import settingReducer from "./settingReducer";
import testimonialReducer from "./testimonialReducer";
import eventReducer from "./eventReducer";
import orderReducer from "./orderReducer";
import zipcodeReducer from "./zipcodeReducer";

import bookingReducer from "./bookingReducer";

import contractReducer from "./contractReducer";

const rootReducer = combineReducers({
  form: formReducer,
  user: authReducer,
  users: userReducer,
  settings: settingReducer,
  testimonials: testimonialReducer,
  events: eventReducer,
  orders: orderReducer,
  bookings: bookingReducer,
  contracts: contractReducer,
  zipcodes : zipcodeReducer,
});

export default rootReducer;
