import { testimonialConstants } from "./types";
import { toast } from "react-toastify";
import api from "../variables/api";

// GET Request to fetch all Testimonials
export const getAllTestimonials = () => {
  const request = () => {
    return { type: testimonialConstants.GET_TESTIMONIAL_REQUEST };
  };
  const success = (testimonials) => {
    return {
      type: testimonialConstants.GET_TESTIMONIAL_SUCCESS,
      testimonials,
    };
  };
  const failure = (error) => {
    return { type: testimonialConstants.GET_TESTIMONIAL_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const testimonialData1 = await api.get("/testimonials");

      const { testimonialData } = testimonialData1.data.data;

      dispatch(success(testimonialData));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

// POST Request to Create Testimonial
export const createTestimonial = ({ payload }) => {
  const request = () => {
    return { type: testimonialConstants.CREATE_TESTIMONIAL_REQUEST };
  };
  const success = () => {
    return {
      type: testimonialConstants.CREATE_TESTIMONIAL_SUCCESS,
    };
  };
  const failure = (error) => {
    return { type: testimonialConstants.CREATE_TESTIMONIAL_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      await api.post("/testimonials", payload);
      toast.success("Created testimonial successfully!");
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

// PATCH Request to Update Testimonial
export const udpateTestimonial = ({ id, payload }) => {
  const request = () => {
    return { type: testimonialConstants.UPDATE_TESTIMONIAL_REQUEST };
  };
  const success = () => {
    return {
      type: testimonialConstants.UPDATE_TESTIMONIAL_SUCCESS,
    };
  };
  const failure = (error) => {
    return { type: testimonialConstants.UPDATE_TESTIMONIAL_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      await api.patch(`/testimonials/updateTestimonial/${id}`, payload);
      toast.success("Updated testimonial successfully!");
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

// DELETE Request to delete Testimonial
export const deleteTestimonial = ({ id }) => {
  const request = () => {
    return { type: testimonialConstants.DELETE_TESTIMONIAL_REQUEST };
  };
  const success = () => {
    return {
      type: testimonialConstants.DELETE_TESTIMONIAL_SUCCESS,
    };
  };
  const failure = (error) => {
    return { type: testimonialConstants.DELETE_TESTIMONIAL_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      await api.delete(`/testimonials/deleteTestimonial/${id}`);
      toast.success("Deleted testimonial successfully!");
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};
