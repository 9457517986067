import React, { Component } from "react";
import { EditorState, convertToRaw, convertFromRaw, RichUtils } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ContractPreviewModal from "./ContractPreviewModal";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
const ContractRichTextEditor = (props) => {
  const [showContractModal, setShowContractModal] = React.useState({
    visible: false,
    data: null,
  });

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  React.useEffect(() => {
    try {
      setEditorState(
        props?.contractValue
          ? EditorState.createWithContent(
              convertFromRaw(JSON.parse(props?.contractValue))
            )
          : EditorState.createEmpty()
      );
    } catch (error) {
      setEditorState(EditorState.createEmpty());
    }
  }, [props?.contractValue]);

  const saveContent = (contarctfile) => {
    props?.onSave(
      JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      contarctfile,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  const onChange = (editorState) => {
    editorState && props?.setContractError && props?.setContractError("");
    setEditorState(editorState);
  };

  return (
    <>
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor contract-editor"
          onEditorStateChange={onChange}
          onTab={(e) => {
            e.preventDefault();

            return "tabpress";
          }}
        />
        {/* <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        /> */}
      </div>
      <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
        {/* {props?.contractID && (
          <Button
            type="button"
            Button
            variant="contained"
            color="primary"
            onClick={saveContent}
          >
            Save
          </Button>
        )} */}
        <div className="form-wrapper mt-4">
          <div className="small-boxs">
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                setShowContractModal({ visible: true });
              }}
            >
              Preview &amp; Save
            </Button>
          </div>
        </div>
        {editorState && showContractModal?.visible &&
          <ContractPreviewModal
            contract={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            onSave={saveContent}
            showContractModal={showContractModal}
            setShowContractModal={setShowContractModal}
          />
        }
      </div>
    </>
  );
};

ContractRichTextEditor.propTypes = {
  onSave: PropTypes.func,
  setContractError: PropTypes.func,
  contractID: PropTypes.any,
  contractValue: PropTypes.any,
};

export default ContractRichTextEditor;
