import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFormik } from "formik";
import * as Yup from "yup";

// core components
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { updateMe } from "../../actions/authAction";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import defaultImage from "../../assets/img/image_placeholder.jpg";
import api from "../../variables/api";

const useStyles = makeStyles(styles);

export default function ExtendedTables() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const { data } = userData;

  const classes = useStyles();

  const [userPhoto, setUserPhoto] = useState(data.photo || "");

  useEffect(() => {
    const urlPattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/;
    const isValidHTTPURL = (url) => urlPattern.test(url);
    async function getImage() {
      if (data && data.photo && !isValidHTTPURL(data.photo)) {
        const image = data.photo;
        const response = await api.post('users/getImageUrl', { image });
        const newimage = response.data.image;
        setUserPhoto(newimage);
      } else {
        setUserPhoto(data.photo);
      }
    }
    getImage();
  }, [data]);

  const formik = useFormik({
    initialValues: {
      email: data && data.email ? data.email : "",
      firstName: data && data.firstName ? data.firstName : "",
      lastName: data && data.lastName ? data.lastName : "",
      phone: data && data.phone ? data.phone : "",
      photo:
        data && data.photo
          ? `${data.photo.replace(`${process.env.REACT_APP_BACKEND_URL}`, "")}`
          : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      phone: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required("A phone number is required")
        .test(
          "maxDigits",
          "Phone number must have atleast 9 digits",
          (number) => String(number).length >= 9
        ),
    }),
    onSubmit: async (values) => {
      let newPhoto;
      if (values.photo === "string") {
        newPhoto = values.photo.replace(
          `${process.env.REACT_APP_BACKEND_URL}`,
          ""
        );
      } else {
        newPhoto = values.photo;
      }
      let formData = new FormData();
      let formValues = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        photo: newPhoto,
      };
      Object.entries(formValues).map((value) => {
        formData.append(value[0], value[1]);
      });
      // console.log(newPhoto, "new photo");
      dispatch(
        updateMe({
          payload: formData,
        })
      );
    },
  });

  if (userData.loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "35px 15px",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Admin Profile</h4>
          </CardHeader>
          <CardBody>
            <form
              onSubmit={formik.handleSubmit}
              className="form form-first-registration"
            >
              <GridContainer>
                <GridItem xs={3} md={3} lg={3}>
                  <CustomInput
                    labelText="First Name"
                    id="firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "firstName",
                      value: formik.values.firstName,
                      onChange: formik.handleChange,
                      touched: formik.touched.firstName,
                      errors: formik.errors.firstName,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} md={3} lg={3}>
                  <CustomInput
                    labelText="Last Name"
                    id="lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "lastName",
                      value: formik.values.lastName,
                      onChange: formik.handleChange,
                      touched: formik.touched.lastName,
                      errors: formik.errors.lastName,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} md={3} lg={3}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "email",
                      name: "email",
                      value: formik.values.email,
                      onChange: formik.handleChange,
                      touched: formik.touched.email,
                      errors: formik.errors.email,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} md={3} lg={3}>
                  <CustomInput
                    labelText="Phone"
                    id="phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "phone",
                      value: formik.values.phone,
                      onChange: formik.handleChange,
                      touched: formik.touched.phone,
                      errors: formik.errors.phone,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} md={3} lg={3}>
                  <div className="company-logo-in">
                    <label>Update Photo</label>
                    <ImageUpload
                      file={formik.values.photo}
                      setFile={(file) => {
                        setUserPhoto(file);
                        formik.setFieldValue("photo", file);
                      }}
                      avatar={userPhoto || defaultImage}
                      addButtonProps={{
                        color: "rose",
                        round: true,
                      }}
                      changeButtonProps={{
                        color: "rose",
                        round: true,
                      }}
                      removeButtonProps={{
                        color: "danger",
                        round: true,
                      }}
                      inputProps={{
                        name: "logo",
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} md={12} lg={12}>
                  <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={formik.isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
