import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput.js";
import Slide from "@material-ui/core/Slide";
import { handleError } from "variables/handleError";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import api from "variables/api";
import { addUser } from "actions/userAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  ...styles,
  ...formStyles,
  ...notificationsStyle,
});

const CreateEmployer = ({ classicModal, setClassicModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [creatingUser, setCreatingUser] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      phone: "",
      role: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is Required"),
      lastName: Yup.string().required("Last Name is Required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
      phone: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required("A phone number is required")
        // .matches("/^[a-z]+$/", "kldclksdmc")
        .test(
          "maxDigits",
          "Phone number must have atleast 9 digits",
          (number) => String(number).length >= 9
        ),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹])(?=.{8,})/,
          "The password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character."
        ),
      // passwordConfirm: Yup.string()
      //   .oneOf([Yup.ref("password"), null], "Passwords must match")
      //   .required("Re-enter Password is Required"),
      passwordConfirm: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
    }),
    onSubmit: async (values) => {
      setCreatingUser(true);
      try {
        const user = await api.post("/users/signup", {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          passwordConfirm: values.passwordConfirm,
          phone: values.phone,
          role: values.role,
        });
        dispatch(addUser(user.data.data.newUser));
      } catch (e) {
        handleError(e);
      }
      setCreatingUser(false);
      setClassicModal(false);
    },
  });

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={classicModal}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        style: {
          maxWidth: "500px",
          width: "100%",
        },
      }}
      onClose={() => setClassicModal(false)}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setClassicModal(false)}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Create User</h4>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          className="form form-first-registration"
        >
          <CustomInput
            labelText="First Name"
            id="firstName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "firstName",
              value: formik.values.firstName,
              onChange: formik.handleChange,
              touched: formik.touched.firstName,
              errors: formik.errors.firstName,
              onBlur: formik.handleBlur,
            }}
          />
          <CustomInput
            labelText="Last Name"
            id="lastName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "lastName",
              value: formik.values.lastName,
              onChange: formik.handleChange,
              touched: formik.touched.lastName,
              errors: formik.errors.lastName,
              onBlur: formik.handleBlur,
            }}
          />
          <CustomInput
            labelText="Email adress"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "email",
              name: "email",
              value: formik.values.email,
              onChange: formik.handleChange,
              touched: formik.touched.email,
              errors: formik.errors.email,
              onBlur: formik.handleBlur,
            }}
          />
          <CustomInput
            labelText="Password"
            id="password"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "password",
              name: "password",
              value: formik.values.password,
              onChange: formik.handleChange,
              touched: formik.touched.password,
              errors: formik.errors.password,
              onBlur: formik.handleBlur,
              autoComplete: "new-password",
            }}
          />
          <CustomInput
            labelText="Confirm Password"
            id="passwordConfirm"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "password",
              name: "passwordConfirm",
              value: formik.values.passwordConfirm,
              onChange: formik.handleChange,
              touched: formik.touched.passwordConfirm,
              errors: formik.errors.passwordConfirm,
              onBlur: formik.handleBlur,
              autoComplete: "new-password",
            }}
          />
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Choose Role
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={formik.values.role}
              onChange={(e) => {
                formik.setFieldValue("role", e.target.value);
              }}
              inputProps={{
                name: "chooseRole",
                id: "choose-role",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose Role
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="employee"
              >
                Employee
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="employer"
              >
                Employer
              </MenuItem>
            </Select>
            {formik.touched.role && formik.errors.role && (
              <div className="error-message">{formik.errors.role}</div>
            )}
          </FormControl>
          <CustomInput
            labelText="Phone Number"
            id="phone"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "phone",
              value: formik.values.phone,
              onChange: formik.handleChange,
              // onChange: (e)=>{e.target.value?.match(/^([+]\d{2})?\d$/) ? formik.handleChange(e) : ''},
              touched: formik.touched.phone,
              errors: formik.errors.phone,
              onBlur: formik.handleBlur,
            }}
          />
          <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
            <Button onClick={() => setClassicModal(false)} variant="outlined">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={creatingUser}
              color="primary"
            >
              Submit
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

CreateEmployer.propTypes = {
  classicModal: PropTypes.any,
  setClassicModal: PropTypes.any,
};

export default CreateEmployer;
