import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import GridItem from "components/Grid/GridItem.js";
import {
  getAllTestimonials,
  createTestimonial,
  udpateTestimonial,
} from "../../actions/testimonialAction";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import api from '../../variables/api';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

function CreateTestimonial({ classicModal, setClassicModal }) {
  const { data } = classicModal;
  const [imgLoading, setImgLoading] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getimage() {
      if (classicModal.type === 'edit') {
          const image = data?.profile;
          const b64i = await api.post('testimonials/getTestimonialImage', { image });
          formik.setFieldValue('profile',b64i.data.image);
          setImgLoading(false)
      }else{
        setImgLoading(false)
      }
    }
    getimage();
  },[]);

  const formik = useFormik({
    initialValues: {
      profile: data && data.profile ? data.profile : "",
      quote: data && data.quote ? data.quote : "",
      name: data && data.name ? data.name : "",
      position: data && data.position ? data.position : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      profile: Yup.mixed().required("Profile Image is required"),
      quote: Yup.string().required("Quote is required"),
      name: Yup.string().required("Name is required"),
      position: Yup.string().required("Position is required"),
    }),
    onSubmit: async (values) => {
      let formData = new FormData();
      let formValues;
      if (classicModal.type === "edit") {
        formValues = {
          ...values,
          uid: data.uid,
        };
      } else {
        formValues = { ...values };
      }

      Object.entries(formValues).map((value) => {
        formData.append(value[0], value[1]);
      });

      if (classicModal.type === "edit") {
        // eslint-disable-next-line no-debugger
        await dispatch(
          udpateTestimonial({
            id: data._id,
            payload: formData,
          })
        );
      } else {
        await dispatch(
          createTestimonial({
            payload: formData,
          })
        );
      }

      await dispatch(getAllTestimonials());

      setClassicModal({ ...classicModal, isVisible: false });
    },
  });

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={classicModal.isVisible}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        style: {
          maxWidth: "500px",
          width: "100%",
        },
      }}
      onClose={() => setClassicModal({ ...classicModal, isVisible: false })}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      {!data && classicModal.type === "edit" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "35px 15px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() =>
                setClassicModal({ ...classicModal, isVisible: false })
              }
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Create Testimonial</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <form onSubmit={formik.handleSubmit} className="form">
              <GridContainer>
                <GridItem xs={12} md={12} lg={12}>
                  <div className="company-logo-in">
                    <label>Update Profile</label>
                    {imgLoading ? <CircularProgress/> :
                    <ImageUpload
                      file={formik.values.profile}
                      setFile={(file) => formik.setFieldValue("profile", file)}
                      avatar={formik.values.profile}
                      addButtonProps={{
                        color: "rose",
                        round: true,
                      }}
                      changeButtonProps={{
                        color: "rose",
                        round: true,
                      }}
                      removeButtonProps={{
                        color: "danger",
                        round: true,
                      }}
                      inputProps={{
                        name: "logo",
                      }}
                    />}
                    {formik.touched.profile && formik.errors.profile && (
                      <div className="error-message">
                        {formik.errors.profile}
                      </div>
                    )}
                  </div>
                </GridItem>
                <GridItem xs={12} md={12} lg={12}>
                  <CustomInput
                    labelText="Quote"
                    id="quote"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "quote",
                      value: formik.values ? formik.values.quote : "",
                      onChange: formik.handleChange,
                      touched: formik.touched.quote,
                      errors: formik.errors.quote,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} md={6} lg={6}>
                  <CustomInput
                    labelText="Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "name",
                      value: formik.values ? formik.values.name : "",
                      onChange: formik.handleChange,
                      touched: formik.touched.name,
                      errors: formik.errors.name,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={6} md={6} lg={6}>
                  <CustomInput
                    labelText="Position"
                    id="position"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "position",
                      value: formik.values ? formik.values.position : "",
                      onChange: formik.handleChange,
                      touched: formik.touched.position,
                      errors: formik.errors.position,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={12} lg={12}>
                  <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={formik.isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </form>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

CreateTestimonial.propTypes = {
  classicModal: PropTypes.bool,
  setClassicModal: PropTypes.func,
};

export default CreateTestimonial;
