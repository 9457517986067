import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { getEvents } from "../../actions/eventAction";
import api from "../../variables/api";
import { handleError } from "../../variables/handleError";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { bonusOptions } from "variables/jobs.constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

function AddJobModal({
  addJobModal,
  setAddJobModal,
  event,
  setLoading,
  setEvent,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [jobPositions, setJobPositions] = useState([]);
  const [staffTypes, setStaffTypes] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        // setLoading(true);
        let response = await api.get('/global-settings');
        let resJobs = response.data.settings[0].staffTypes;
        setStaffTypes(resJobs);
        setLoading(false);
        const filteredJobPositions = resJobs.map((job) => ({
          label: job.name,
          value: job.name,
        }));
        setJobPositions(filteredJobPositions);
        // console.log("filteredJobPositions",filteredJobPositions)
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    })();
  }, []);
  const formik = useFormik({
    initialValues: {
      jobName: "",
      jobRequirements: "",
      jobBonus: null,
      jobDescription: "",
    },
    validationSchema: Yup.object({
      jobName: Yup.string().required("Job name is required"),
      // jobRequirements: Yup.string().required("Job Requirements is required"),
      jobBonus: Yup.number()
        .required("Job Bonus is required")
        .typeError("Field must be a number"),
      // jobDescription: Yup.string().required("Job Description is required"),
    }),
    onSubmit: async (values) => {
      const staffTypeId = staffTypes.find((s) => s.name === values.jobName)._id;
      setLoading(true);
      try {
        await api.post("/jobs", {
          ...values,
          jobBonus: `${values.jobBonus}%`,
          eventName: event.eventName,
          eventID: event._id,
          staffType: staffTypeId
        });
        toast.success("Created Job Successfully!");
        const dataEvent = await api.get(`/events/getEvent/${id}`);
        setEvent(dataEvent.data.data.event);
        window.location.reload();
        // setLoading(false);
        setAddJobModal({ ...addJobModal, isVisible: false });
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    },
  });

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={addJobModal.isVisible}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        style: {
          maxWidth: "500px",
          width: "100%",
        },
      }}
      onClose={() => setAddJobModal({ ...addJobModal, isVisible: false })}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setAddJobModal({ ...addJobModal, isVisible: false })}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Add Job</h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <form onSubmit={formik.handleSubmit} className="form">
          <GridContainer>
            <GridItem xs={12} md={12} lg={12}>

              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Job Name
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={formik.values.jobName}
                  onChange={(e) => {
                    formik.setFieldValue("jobName", e.target.value);
                  }}
                  inputProps={{
                    name: "jobName",
                    id: "jobName",
                    // selected: jobName,
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Job Type
                  </MenuItem>
                  {jobPositions.map((jobPosition, id) => (
                    <MenuItem
                      key={jobPosition + id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={jobPosition.value}
                    >
                      {jobPosition.label}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.jobName && formik.errors.jobName && (
                  <div className="error-message">
                    {formik.errors.jobName}
                  </div>
                )}
              </FormControl>
            </GridItem>
            <GridItem xs={12} md={12} lg={12}>
              <CustomInput
                labelText="Job Requirements"
                id="jobRequirements"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  name: "jobRequirements",
                  value: formik.values.jobRequirements,
                  onChange: formik.handleChange,
                  touched: formik.touched.jobRequirements,
                  errors: formik.errors.jobRequirements,
                  onBlur: formik.handleBlur,
                }}
              />
            </GridItem>
            <GridItem xs={12} md={12} lg={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Job Bonus (in % percentage)
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={formik.values.jobBonus}
                  onChange={(e) => {
                    formik.setFieldValue("jobBonus", e.target.value);
                  }}
                  inputProps={{
                    name: "jobBonus",
                    id: "jobBonus",
                    // selected: jobBonus,
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Job Bonus
                  </MenuItem>
                  {bonusOptions.map((bonus, id) => (
                    <MenuItem
                      key={bonus + id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={bonus.value}
                    >
                      {bonus.label}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.jobBonus && formik.errors.jobBonus && (
                  <div className="error-message">
                    {formik.errors.jobBonus}
                  </div>
                )}
              </FormControl>
            </GridItem>
            <GridItem xs={12} md={12} lg={12}>
              <CustomInput
                labelText="Job Description"
                id="jobDescription"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  name: "jobDescription",
                  value: formik.values.jobDescription,
                  onChange: formik.handleChange,
                  touched: formik.touched.jobDescription,
                  errors: formik.errors.jobDescription,
                  onBlur: formik.handleBlur,
                }}
              />
            </GridItem>
            <GridItem xs={12} md={12} lg={12}>
              <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                >
                  Add Job
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </form>
      </DialogContent>
    </Dialog>
  );
}

AddJobModal.propTypes = {
  addJobModal: PropTypes.bool,
  setAddJobModal: PropTypes.func,
  event: PropTypes.any,
  setLoading: PropTypes.any,
  setEvent: PropTypes.any,
};

export default AddJobModal;
