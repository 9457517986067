import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

const CheckboxWrapper = ({
  name,
  selectedEnabled,
  handleChangeEnabled,
  options,
}) => {
  const classes = useStyles();

  return (
    <div className="btn-radio-wrapper">
      <label>Gender</label>
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        {options.map((option, id) => (
          <FormControlLabel
            key={option + id}
            control={
              <Radio
                checked={selectedEnabled === option.value}
                onChange={handleChangeEnabled}
                value={option.value}
                name={name}
                aria-label={option.value}
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label={option.label}
          />
        ))}
      </div>
    </div>
  );
};

CheckboxWrapper.propTypes = {
  name: PropTypes.any,
  selectedEnabled: PropTypes.any,
  handleChangeEnabled: PropTypes.any,
  options: PropTypes.any,
};

export default CheckboxWrapper;
