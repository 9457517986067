import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = (props) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center custom-loader"
      {...props}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Loader;
