import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

// @material-ui/core components
import { makeStyles, styled } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { login } from "../../actions/authAction";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

const CustomLink = styled(Link)({
  textAlign: "center",
  marginTop: "10px",
  marginLeft: "5px",
  flex: "0 0 100%",
  maxWidth: "100%",
});

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export default function LoginPage(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectTo =
    props.location && props.location.state && props.location.state.lastLocation
      ? props.location.state.lastLocation
      : "/admin/dashboard";
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: ({ email, password }) => {
      dispatch(
        login({
          email,
          password,
          callback: () => history.push(`${redirectTo}`),
          setSubmitting: formik.setSubmitting,
        })
      );
    },
  });

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={formik.handleSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "email",
                    name: "email",
                    autoFocus: true,
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    name: "password",
                    autoComplete: "off",
                  }}
                />
              </CardBody>
              <CardFooter
                className={`${classes.justifyContentCenter} ${classes.flexWrap}`}
              >
                {/* <Link
                  className={classes.forgotPassword}
                  to={"/auth/forget-password"}
                >
                  Forgot Password
                </Link> */}
                <Button
                  type="submit"
                  color="primary"
                  size="lg"
                  block
                  disabled={formik.isSubmitting}
                >
                  Let{"'"}s Go
                </Button>
                {/* <div className={classes.createAccount}>
                  Not registered yet ?
                  <CustomLink to={"/auth/register"}>
                    Create an account
                  </CustomLink>
                </div> */}
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

LoginPage.propTypes = {
  location: PropTypes.object,
};
