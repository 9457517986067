export const userConstants = {
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  UDPATE_ME_REQUEST: "UDPATE_ME_REQUEST",
  UDPATE_ME_SUCCESS: "UDPATE_ME_SUCCESS",
  UDPATE_ME_FAILURE: "UDPATE_ME_FAILURE",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  FORGET_PASSWORD_REQUEST: "FORGET_PASSWORD_REQUEST",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_FAILURE: "FORGET_PASSWORD_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  DELETE_USERS_REQUEST: "DELETE_USERS_REQUEST",
  DELETE_USERS_SUCCESS: "DELETE_USERS_SUCCESS",
  DELETE_USERS_FAILURE: "DELETE_USERS_FAILURE",
  UPDATE_USERS_REQUEST: "UPDATE_USERS_REQUEST",
  UPDATE_USERS_SUCCESS: "UPDATE_USERS_SUCCESS",
  UPDATE_USERS_FAILURE: "UPDATE_USERS_FAILURE",
  ADD_USER_REQUEST: "ADD_USER_REQUEST",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",
  RESTORE_USER_REQUEST: "RESTORE_USER_REQUEST",
  RESTORE_USER_SUCCESS: "RESTORE_USER_SUCCESS",
  RESTORE_USER_FAILURE: "RESTORE_USER_FAILURE",
  GET_UNAPPROVED_USERS_REQUEST: "GET_UNAPPROVED_USERS_REQUEST",
  GET_UNAPPROVED_USERS_SUCCESS: "GET_UNAPPROVED_USERS_SUCCESS",
  GET_UNAPPROVED_USERS_FAILURE: "GET_UNAPPROVED_USERS_FAILURE",
  APPROVE_USER_REQUEST: "APPROVE_USER_REQUEST",
  APPROVE_USER_SUCCESS: "APPROVE_USER_SUCCESS",
  APPROVE_USER_FAILURE: "APPROVE_USER_FAILURE",
};

export const employerConstants = {
  GET_EMPLOYERS_REQUEST: "GET_EMPLOYERS_REQUEST",
  GET_EMPLOYERS_SUCCESS: "GET_EMPLOYERS_SUCCESS",
  GET_EMPLOYERS_FAILURE: "GET_EMPLOYERS_FAILURE",
  DELETE_EMPLOYER_REQUEST: "DELETE_EMPLOYER_REQUEST",
  DELETE_EMPLOYER_SUCCESS: "DELETE_EMPLOYER_SUCCESS",
  DELETE_EMPLOYER_FAILURE: "DELETE_EMPLOYER_FAILURE",
  UPDATE_EMPLOYER_REQUEST: "UPDATE_EMPLOYER_REQUEST",
  UPDATE_EMPLOYER_SUCCESS: "UPDATE_EMPLOYER_SUCCESS",
  UPDATE_EMPLOYER_FAILURE: "UPDATE_EMPLOYER_FAILURE",
};

export const employeeConstants = {
  GET_EMPLOYEES_REQUEST: "GET_EMPLOYEES_REQUEST",
  GET_EMPLOYEES_SUCCESS: "GET_EMPLOYEES_SUCCESS",
  GET_EMPLOYEES_FAILURE: "GET_EMPLOYEES_FAILURE",
  DELETE_EMPLOYEE_REQUEST: "DELETE_EMPLOYEE_REQUEST",
  DELETE_EMPLOYEE_SUCCESS: "DELETE_EMPLOYEE_SUCCESS",
  DELETE_EMPLOYEE_FAILURE: "DELETE_EMPLOYEE_FAILURE",
  UPDATE_EMPLOYEE_REQUEST: "UPDATE_EMPLOYEE_REQUEST",
  UPDATE_EMPLOYEE_SUCCESS: "UPDATE_EMPLOYEE_SUCCESS",
  UPDATE_EMPLOYEE_FAILURE: "UPDATE_EMPLOYEE_FAILURE",
};

export const settingConstants = {
  GET_SETTINGS_REQUEST: "GET_SETTINGS_REQUEST",
  GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
  GET_SETTINGS_FAILURE: "GET_SETTINGS_FAILURE",
  UPDATE_SETTINGS_REQUEST: "UPDATE_SETTINGS_REQUEST",
  UPDATE_SETTINGS_SUCCESS: "UPDATE_SETTINGS_SUCCESS",
  UPDATE_SETTINGS_FAILURE: "UPDATE_SETTINGS_FAILURE",
};

export const testimonialConstants = {
  CREATE_TESTIMONIAL_REQUEST: "CREATE_TESTIMONIAL_REQUEST",
  CREATE_TESTIMONIAL_SUCCESS: "CREATE_TESTIMONIAL_SUCCESS",
  CREATE_TESTIMONIAL_FAILURE: "CREATE_TESTIMONIAL_FAILURE",
  GET_TESTIMONIAL_REQUEST: "GET_TESTIMONIAL_REQUEST",
  GET_TESTIMONIAL_SUCCESS: "GET_TESTIMONIAL_SUCCESS",
  GET_TESTIMONIAL_FAILURE: "GET_TESTIMONIAL_FAILURE",
  UPDATE_TESTIMONIAL_REQUEST: "UPDATE_TESTIMONIAL_REQUEST",
  UPDATE_TESTIMONIAL_SUCCESS: "UPDATE_TESTIMONIAL_SUCCESS",
  UPDATE_TESTIMONIAL_FAILURE: "UPDATE_TESTIMONIAL_FAILURE",
  DELETE_TESTIMONIAL_REQUEST: "DELETE_TESTIMONIAL_REQUEST",
  DELETE_TESTIMONIAL_SUCCESS: "DELETE_TESTIMONIAL_SUCCESS",
  DELETE_TESTIMONIAL_FAILURE: "DELETE_TESTIMONIAL_FAILURE",
};
export const zipcodeConstants = {
  CREATE_ZIPCODE_REQUEST: "CREATE_ZIPCODE_REQUEST",
  CREATE_ZIPCODE_SUCCESS: "CREATE_ZIPCODE_SUCCESS",
  CREATE_ZIPCODE_FAILURE: "CREATE_ZIPCODE_FAILURE",
  GET_ZIPCODE_REQUEST: "GET_ZIPCODE_REQUEST",
  GET_ZIPCODE_SUCCESS: "GET_ZIPCODE_SUCCESS",
  GET_ZIPCODE_FAILURE: "GET_ZIPCODE_FAILURE",
  UPDATE_ZIPCODE_REQUEST: "UPDATE_ZIPCODE_REQUEST",
  UPDATE_ZIPCODE_SUCCESS: "UPDATE_ZIPCODE_SUCCESS",
  UPDATE_ZIPCODE_FAILURE: "UPDATE_ZIPCODE_FAILURE",
  DELETE_ZIPCODE_REQUEST: "DELETE_ZIPCODE_REQUEST",
  DELETE_ZIPCODE_SUCCESS: "DELETE_ZIPCODE_SUCCESS",
  DELETE_ZIPCODE_FAILURE: "DELETE_ZIPCODE_FAILURE",
};

export const eventConstants = {
  CREATE_EVENT_REQUEST: "CREATE_EVENT_REQUEST",
  CREATE_EVENT_SUCCESS: "CREATE_EVENT_SUCCESS",
  CREATE_EVENT_FAILURE: "CREATE_EVENT_FAILURE",
  GET_EVENT_REQUEST: "GET_EVENT_REQUEST",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  GET_EVENT_FAILURE: "GET_EVENT_FAILURE",
  UPDATE_EVENT_REQUEST: "UPDATE_EVENT_REQUEST",
  UPDATE_EVENT_SUCCESS: "UPDATE_EVENT_SUCCESS",
  UPDATE_EVENT_FAILURE: "UPDATE_EVENT_FAILURE",
  DELETE_EVENT_REQUEST: "DELETE_EVENT_REQUEST",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  DELETE_EVENT_FAILURE: "DELETE_EVENT_FAILURE",
};

export const orderConstants = {
  CREATE_ORDER_REQUEST: "CREATE_ORDER_REQUEST",
  CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
  CREATE_ORDER_FAILURE: "CREATE_ORDER_FAILURE",
  GET_ORDER_REQUEST: "GET_ORDER_REQUEST",
  GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  GET_ORDER_FAILURE: "GET_ORDER_FAILURE",
  UPDATE_ORDER_REQUEST: "UPDATE_ORDER_REQUEST",
  UPDATE_ORDER_SUCCESS: "UPDATE_ORDER_SUCCESS",
  UPDATE_ORDER_FAILURE: "UPDATE_ORDER_FAILURE",
  DELETE_ORDER_REQUEST: "DELETE_ORDER_REQUEST",
  DELETE_ORDER_SUCCESS: "DELETE_ORDER_SUCCESS",
  DELETE_ORDER_FAILURE: "DELETE_ORDER_FAILURE",
};

export const staffTypeConstants = {
  CREATE_STAFFTYPE_REQUEST: "CREATE_STAFFTYPE_REQUEST",
  CREATE_STAFFTYPE_SUCCESS: "CREATE_STAFFTYPE_SUCCESS",
  CREATE_STAFFTYPE_FAILURE: "CREATE_STAFFTYPE_FAILURE",
  GET_STAFFTYPE_REQUEST: "GET_STAFFTYPE_REQUEST",
  GET_STAFFTYPE_SUCCESS: "GET_STAFFTYPE_SUCCESS",
  GET_STAFFTYPE_FAILURE: "GET_STAFFTYPE_FAILURE",
  UPDATE_STAFFTYPE_REQUEST: "UPDATE_STAFFTYPE_REQUEST",
  UPDATE_STAFFTYPE_SUCCESS: "UPDATE_STAFFTYPE_SUCCESS",
  UPDATE_STAFFTYPE_FAILURE: "UPDATE_STAFFTYPE_FAILURE",
  DELETE_STAFFTYPE_REQUEST: "DELETE_STAFFTYPE_REQUEST",
  DELETE_STAFFTYPE_SUCCESS: "DELETE_STAFFTYPE_SUCCESS",
  DELETE_STAFFTYPE_FAILURE: "DELETE_STAFFTYPE_FAILURE",
};

export const contractsConstants = {
  CREATE_CONTRACT_REQUEST: "CREATE_CONTRACT_REQUEST",
  CREATE_CONTRACT_SUCCESS: "CREATE_CONTRACT_SUCCESS",
  CREATE_CONTRACT_FAILURE: "CREATE_CONTRACT_FAILURE",
  GET_CONTRACT_REQUEST: "GET_CONTRACT_REQUEST",
  GET_CONTRACT_SUCCESS: "GET_CONTRACT_SUCCESS",
  GET_CONTRACT_FAILURE: "GET_CONTRACT_FAILURE",
  UPDATE_CONTRACT_REQUEST: "UPDATE_CONTRACT_REQUEST",
  UPDATE_CONTRACT_SUCCESS: "UPDATE_CONTRACT_SUCCESS",
  UPDATE_CONTRACT_FAILURE: "UPDATE_CONTRACT_FAILURE",
  DELETE_CONTRACT_REQUEST: "DELETE_CONTRACT_REQUEST",
  DELETE_CONTRACT_SUCCESS: "DELETE_CONTRACT_SUCCESS",
  DELETE_CONTRACT_FAILURE: "DELETE_CONTRACT_FAILURE",
};

export const bookingConstants = {
  GET_BOOKING_REQUEST: "GET_BOOKING_REQUEST",
  GET_BOOKING_SUCCESS: "GET_BOOKING_SUCCESS",
  GET_BOOKING_FAILURE: "GET_BOOKING_FAILURE",
  UPDATE_BOOKING_REQUEST: "UPDATE_BOOKING_REQUEST",
  UPDATE_BOOKING_SUCCESS: "UPDATE_BOOKING_SUCCESS",
  UPDATE_BOOKING_FAILURE: "UPDATE_BOOKING_FAILURE",
};


