import { zipcodeConstants } from "./types";
import { toast } from "react-toastify";
import api from "../variables/api";

// POST Request to Create Testimonial
export const importzipcodes = ({ payload }) => {

  const request = () => {
    return { type: zipcodeConstants.CREATE_ZIPCODE_REQUEST };
  };
  const success = () => {
    return {
      type: zipcodeConstants.CREATE_ZIPCODE_SUCCESS,
    };
  };
  const failure = (error) => {
    return { type: zipcodeConstants.CREATE_ZIPCODE_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      await api.post("/zipcodes", payload);
      toast.success("ZipCode imported successfully!");
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

export const getzipcodes = () => {
  const request = () => {
    return { type: zipcodeConstants.GET_ZIPCODE_REQUEST };
  };
  const success = (zipcodes) => {
    return { type: zipcodeConstants.GET_ZIPCODE_SUCCESS, zipcodes };
  };
  const failure = (error) => {
    return { type: zipcodeConstants.GET_ZIPCODE_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const zipcodes = await api.get("/zipcodes");

      dispatch(success(zipcodes));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong!"));
        toast.error("Something went wrong!");
      }
    }
  };

}