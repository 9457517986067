export const roles = {
  employer: "employer",
  employee: "employee",
  admin: "admin",
  superuser : "superuser"
};

export const labelRoles = {
  employer: "organization",
  employee: "staff",
  superuser : "superuser"
};
