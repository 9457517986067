import Buffer from 'buffer';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";

import PrivateRoute from "./components/Routes/PrivateRoute";
import PublicRoute from "./components/Routes/PublicRoute";
import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import reportWebVitals from "./reportWebVitals";

import "react-toastify/dist/ReactToastify.css";
import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";

window.Buffer = Buffer.Buffer;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer autoClose={4000} position="top-center" theme="colored" />
    <BrowserRouter>
      <Switch>
        <Route path="/rtl" component={RtlLayout} />
        <PublicRoute restricted={true} path="/auth" component={AuthLayout} />
        <PrivateRoute path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/admin/users" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals(console.log);
