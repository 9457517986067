import { userConstants } from "./types";
import { toast } from "react-toastify";
import api from "../variables/api";
import { roles } from "../variables/roles";

export const signup = (data) => {
  const {
    name,
    email,
    password,
    confirmPassword,
    callback,
    setSubmitting,
  } = data;

  const request = () => {
    return { type: userConstants.LOGIN_REQUEST };
  };
  const success = (token) => {
    return { type: userConstants.LOGIN_SUCCESS, token };
  };
  const failure = (error) => {
    return { type: userConstants.LOGIN_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await api.post("/users/signup", {
        name,
        email,
        password,
        passwordConfirm: confirmPassword,
      });
      dispatch(success(user.data.token));
      setSubmitting(false);
      localStorage.setItem("token", user.data.token);
      callback();
    } catch (error) {
      setSubmitting(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

export const login = (data) => {
  const { email, password, callback, setSubmitting } = data;
  const request = () => {
    return { type: userConstants.LOGIN_REQUEST };
  };
  const success = (user) => {
    return { type: userConstants.LOGIN_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: userConstants.LOGIN_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await api.post("users/login", {
        email,
        password,
        role: roles.admin,
      });
      dispatch(
        success({
          ...user.data.data.user,
          token: user.data.token,
        })
      );
      setSubmitting(false);
      localStorage.setItem("user", JSON.stringify(user.data.data.user));
      localStorage.setItem("token", user.data.token);
      callback();
    } catch (error) {
      setSubmitting(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

export const updateMe = ({ payload }) => {
  const request = () => {
    return { type: userConstants.UDPATE_ME_REQUEST };
  };
  const success = (user) => {
    return { type: userConstants.UDPATE_ME_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: userConstants.UDPATE_ME_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await api.patch("users/updateMe", payload);
      dispatch(
        success({
          ...user.data.data.user,
        })
      );
      localStorage.setItem("user", JSON.stringify(user.data.data.user));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

export const logout = (callback) => {
  const request = () => {
    return { type: userConstants.LOGOUT_REQUEST };
  };
  const success = () => {
    return { type: userConstants.LOGOUT_SUCCESS };
  };
  const failure = (error) => {
    return { type: userConstants.LOGOUT_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    api
      .get("users/logout")
      .then((res) => {
        dispatch(success());
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        callback();
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(failure(`${error.response.data.message}`));
          toast.error(`${error.response.data.message}`);
        } else {
          dispatch(failure("Something went wrong"));
          toast.error("Something went wrong");
        }
      });
  };
};

export const forgetPassword = (email, setSubmitting) => {
  const request = () => {
    return { type: userConstants.FORGET_PASSWORD_REQUEST };
  };
  const success = () => {
    return { type: userConstants.FORGET_PASSWORD_SUCCESS };
  };
  const failure = (error) => {
    return { type: userConstants.FORGET_PASSWORD_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    api
      .post("users/forgotPassword", { email })
      .then((res) => {
        dispatch(success());
        setSubmitting(false);
        toast.success(`${res.data.message}`);
      })
      .catch((error) => {
        setSubmitting(false);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(failure(`${error.response.data.message}`));
          toast.error(`${error.response.data.message}`);
        } else {
          dispatch(failure("Something went wrong"));
          toast.error("Something went wrong");
        }
      });
  };
};

export const resetPassword = (data) => {
  const { password, confirmPassword, token, setSubmitting, callback } = data;

  const request = () => {
    return { type: userConstants.RESET_PASSWORD_REQUEST };
  };
  const success = () => {
    return { type: userConstants.RESET_PASSWORD_SUCCESS };
  };
  const failure = (error) => {
    return { type: userConstants.RESET_PASSWORD_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const user = await api.patch(`users/resetPassword/${token}`, {
        password,
        passwordConfirm: confirmPassword,
      });
      dispatch(success());
      setSubmitting(false);
      toast.success(`Password changed successfully!`);
      localStorage.setItem("token", user.data.token);
      callback();
    } catch (error) {
      setSubmitting(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};
