import { contractsConstants } from "./types";
import { toast } from "react-toastify";
import api from "../variables/api";

// GET Request to fetch all Contracts
export const getAllContracts = () => {
  // eslint-disable-next-line no-debugger

  const request = () => {
    return { type: contractsConstants.GET_CONTRACT_REQUEST };
  };
  const success = (contracts) => {
    return {
      type: contractsConstants.GET_CONTRACT_SUCCESS,
      contracts,
    };
  };
  const failure = (error) => {
    return { type: contractsConstants.GET_CONTRACT_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const contractData = await api.get("/contracts");
      // const { contract } = contractData.data.data;
      dispatch(success(contractData.data.data));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

// POST Request to Create Contract
export const createContract = ({ payload, onSuccess, onFailure}) => {
  // eslint-disable-next-line no-debugger
  // debugger
  const request = () => {
    return { type: contractsConstants.CREATE_CONTRACT_REQUEST };
  };
  const success = () => {
    return {
      type: contractsConstants.CREATE_CONTRACT_SUCCESS,
    };
  };
  const failure = (error) => {
    return { type: contractsConstants.CREATE_CONTRACT_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const res = await api.post("/contracts", payload);
      toast.success("Created contract successfully!");
      onSuccess && onSuccess(res);
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        onFailure && onFailure(error.response);
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        onFailure && onFailure(error.response);
        toast.error("Something went wrong");
      }
    }
  };
};

// PATCH Request to Update Contract
export const udpateContract = ({ id, payload, onSuccess, onFailure }) => {
  const request = () => {
    return { type: contractsConstants.UPDATE_CONTRACT_REQUEST };
  };
  const success = () => {
    return {
      type: contractsConstants.UPDATE_CONTRACT_SUCCESS,
    };
  };
  const failure = (error) => {
    return { type: contractsConstants.UPDATE_CONTRACT_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const res = await api.patch(`/contracts/updateContracts/${id}`, payload);
      toast.success("Updated contract successfully!");
      onSuccess && onSuccess(res);
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        onFailure && onFailure(error.response);
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        onFailure && onFailure(error.response);
        toast.error("Something went wrong");
      }
    }
  };
};

// DELETE Request to delete Contract
export const deleteContract = ({ id, onSuccess, onFailure }) => {
  const request = () => {
    return { type: contractsConstants.DELETE_CONTRACT_REQUEST };
  };
  const success = () => {
    return {
      type: contractsConstants.DELETE_CONTRACT_SUCCESS,
    };
  };
  const failure = (error) => {
    return { type: contractsConstants.DELETE_CONTRACT_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      await api.delete(`/contracts/deleteContract/${id}`);
      toast.success("Deleted contract successfully!");
      onSuccess && onSuccess("Contract Deleted");
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        onFailure && onFailure(error.response);
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        onFailure && onFailure(error.response);
        toast.error("Something went wrong");
      }
    }
  };
};
