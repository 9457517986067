import { settingConstants } from "../actions/types";

const initialState = { loading: false, data: [], error: "" };

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingConstants.GET_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: "",
      };
    case settingConstants.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.settings,
        error: "",
      };
    case settingConstants.GET_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    default:
      return { ...state };
  }
};

export default settingReducer;
