import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
// @material-ui/core components
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
// import Job from "./Job";
// import AddJobModal from "./AddJobModal";
import api from "../../variables/api";
import { handleError } from "../../variables/handleError";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { disablePastDates } from "variables/utils";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

const CreateOrder = () => {
  const [addJobModal, setAddJobModal] = useState({
    isVisible: false,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      orderDate: "",
      promocode: "",
      status: "",
      billingAddressName: "",
      billingAddressPostCode: "",
      billingAddressCity: "",
      billingAddressCountry: "",
      billingAddressStreet: "",
      paymentMethod: "",
      paymentStatus: "",
      transactionDate: "",
      stripeId: "",
      transationId: "",
      cartItems: [
        {
          companyName: '',
          eventID: null,
          cartItemCurrency: null,
          cartItemName: "",
          cartItemPosition: "",
          cartItemPrice: null,
          cartItemVat: null,
        },
      ],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      orderDate: Yup.number()
        .typeError("Provide Valid Date")
        .required("Field is required"),
      promocode: Yup.string().required("Field is required"),
      status: Yup.string().required("Field is required"),
      billingAddressName: Yup.string().required("Field is required"),
      billingAddressPostCode: Yup.string().required("Field is required"),
      billingAddressCity: Yup.string().required("Field is required"),
      billingAddressCountry: Yup.string().required("Field is required"),
      billingAddressStreet: Yup.string().required("Field is required"),
      billingAddressStreetTwo: Yup.string().required("Field is required"),
      paymentMethod: Yup.string().required("Field is required"),
      paymentStatus: Yup.string().required("Field is required"),
      transactionDate: Yup.number().required("Field is required"),
    }),
    onSubmit: async (values) => {
      try {
        const newValues = {
          status: values.status,
          orderDate: values.orderDate,
          promocode: values.promocode,
          stripeId: values.stripeId,
          transationId: values.transationId,
          billingAddress: {
            billingAddressCity: values.billingAddressCity,
            billingAddressName: values.billingAddressName,
            billingAddressCountry: values.billingAddressCountry,
            billingAddressPostCode: values.billingAddressPostCode,
            billingAddressStreet: values.billingAddressStreet,
            billingAddressStreetTwo: values.billingAddressStreetTwo,
          },
          cartItems: values.cartItems,
          payment: {
            paymentMethod: values.paymentMethod,
            paymentStatus: values.paymentStatus,
            transactionDate: values.transactionDate,
          },
        };
        await api.post(`/orders/`, {
          ...newValues,
        });
        toast.success("Created order Successfully!");
        history.push("/admin/orders");
      } catch (error) {
        handleError(error);
      }
    },
  });

  return (
    <GridContainer>
      <GridItem xs={12}>
        {alert}
        <div className="go-back">
          <span onClick={(e) => history.push("/admin/orders")}>
            <ArrowBackIosIcon />
            <span className="text-in">Go Back</span>
          </span>
        </div>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Create Order</h4>
          </CardHeader>
          <CardBody>
            <form onSubmit={formik.handleSubmit}>
              <GridContainer>
                <GridItem xs={3} lg={3}>
                  <InputLabel className={classes.label}>Order Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      isValidDate={disablePastDates}
                      timeFormat={false}
                      dateFormat="DD-MM-YYYY"
                      initialValue={formik.values.orderDate}
                      inputProps={{ placeholder: "Date" }}
                      closeOnSelect={true}
                      onChange={(e) => {
                        var d = e._d;
                        const time = new Date(d).valueOf();
                        formik.setFieldValue("orderDate", time);
                      }}
                    />
                  </FormControl>
                  {formik.touched.orderDate && formik.errors.orderDate && (
                    <div className="error-message">
                      {formik.errors.orderDate}
                    </div>
                  )}
                </GridItem>
                <GridItem xs={3} lg={3}>
                  <CustomInput
                    labelText="Promo Code"
                    id="promocode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "promocode",
                      value: formik.values.promocode,
                      onChange: formik.handleChange,
                      touched: formik.touched.promocode,
                      errors: formik.errors.promocode,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Choose Status
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={formik.values.status}
                      onChange={(e) => {
                        formik.setFieldValue("status", e.target.value);
                      }}
                      inputProps={{
                        name: "chooseStatus",
                        id: "choose-status",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Choose Status
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="successful"
                      >
                        Successful
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="rejected"
                      >
                        Rejected
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="pending"
                      >
                        Pending
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="cancelled"
                      >
                        Cancelled
                      </MenuItem>
                    </Select>
                    {formik.touched.status && formik.errors.status && (
                      <div className="error-message">
                        {formik.errors.status}
                      </div>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} lg={12}>
                  <h4>Billing Address</h4>
                </GridItem>
                <GridItem xs={3} lg={3}>
                  <CustomInput
                    labelText="Name"
                    id="billingAddressName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "billingAddressName",
                      value: formik.values.billingAddressName,
                      onChange: formik.handleChange,
                      touched: formik.touched.billingAddressName,
                      errors: formik.errors.billingAddressName,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} lg={3}>
                  <CustomInput
                    labelText="Post Code"
                    id="billingAddressPostCode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "billingAddressPostCode",
                      value: formik.values.billingAddressPostCode,
                      onChange: formik.handleChange,
                      touched: formik.touched.billingAddressPostCode,
                      errors: formik.errors.billingAddressPostCode,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} lg={3}>
                  <CustomInput
                    labelText="City"
                    id="billingAddressCity"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "billingAddressCity",
                      value: formik.values.billingAddressCity,
                      onChange: formik.handleChange,
                      touched: formik.touched.billingAddressCity,
                      errors: formik.errors.billingAddressCity,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} lg={3}>
                  <CustomInput
                    labelText="Country"
                    id="billingAddressCountry"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "billingAddressCountry",
                      value: formik.values.billingAddressCountry,
                      onChange: formik.handleChange,
                      touched: formik.touched.billingAddressCountry,
                      errors: formik.errors.billingAddressCountry,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} lg={12}>
                  <CustomInput
                    labelText="Street Line One"
                    id="billingAddressStreet"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "billingAddressStreet",
                      value: formik.values.billingAddressStreet,
                      onChange: formik.handleChange,
                      touched: formik.touched.billingAddressStreet,
                      errors: formik.errors.billingAddressStreet,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} lg={12}>
                  <CustomInput
                    labelText="Street Line Two"
                    id="billingAddressStreetTwo"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "billingAddressStreetTwo",
                      value: formik.values.billingAddressStreetTwo,
                      onChange: formik.handleChange,
                      touched: formik.touched.billingAddressStreetTwo,
                      errors: formik.errors.billingAddressStreetTwo,
                      onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} lg={12}>
                  <h4>Payment Details</h4>
                </GridItem>
                <GridItem xs={3} lg={3}>
                  <InputLabel className={classes.label}>
                    Transaction Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      isValidDate={disablePastDates}
                      timeFormat={false}
                      dateFormat="DD-MM-YYYY"
                      initialValue={formik.values.transactionDate}
                      inputProps={{ placeholder: "Date" }}
                      closeOnSelect={true}
                      onChange={(e) => {
                        var d = e._d;
                        const time = new Date(d).valueOf();
                        formik.setFieldValue("transactionDate", time);
                      }}
                    />
                  </FormControl>
                  {formik.touched.transactionDate &&
                    formik.errors.transactionDate && (
                      <div className="error-message">
                        {formik.errors.transactionDate}
                      </div>
                    )}
                </GridItem>
                <GridItem xs={3} lg={3}>
                  <CustomInput
                    labelText="StripeID"
                    id="stripeId"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "stripeId",
                      value: formik.values.stripeId,
                      onChange: formik.handleChange,
                      // touched: formik.touched.promocode,
                      // errors: formik.errors.promocode,
                      // onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} lg={3}>
                  <CustomInput
                    labelText="TransationID"
                    id="transationId"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      name: "transationId",
                      value: formik.values.transationId,
                      onChange: formik.handleChange,
                      // touched: formik.touched.promocode,
                      // errors: formik.errors.promocode,
                      // onBlur: formik.handleBlur,
                    }}
                  />
                </GridItem>
                <GridItem xs={3} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Choose Payment Method
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={formik.values.paymentMethod}
                      onChange={(e) => {
                        formik.setFieldValue("paymentMethod", e.target.value);
                      }}
                      inputProps={{
                        name: "choosePaymentMethod",
                        id: "choose-payment-method",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Choose Payment Method
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="creditCard"
                      >
                        Credit Card
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="invoice"
                      >
                        Invoice
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="netbanking"
                      >
                        Netbanking
                      </MenuItem>
                    </Select>
                    {formik.touched.paymentMethod &&
                      formik.errors.paymentMethod && (
                        <div className="error-message">
                          {formik.errors.paymentMethod}
                        </div>
                      )}
                  </FormControl>
                </GridItem>
                <GridItem xs={3} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Choose Payment Status
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={formik.values.paymentStatus}
                      onChange={(e) => {
                        formik.setFieldValue("paymentStatus", e.target.value);
                      }}
                      inputProps={{
                        name: "choosePaymentStatus",
                        id: "choose-payment-status",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Choose Payment Status
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="successful"
                      >
                        Successful
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="rejected"
                      >
                        Rejected
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="pending"
                      >
                        Pending
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="refunded"
                      >
                        Refunded
                      </MenuItem>
                    </Select>
                    {formik.touched.paymentStatus &&
                      formik.errors.paymentStatus && (
                        <div className="error-message">
                          {formik.errors.paymentStatus}
                        </div>
                      )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} lg={12}>
                  <h4>Order Item</h4>
                </GridItem>
                <GridItem xs={12} lg={12}>
                  {formik.values &&
                  formik.values.cartItems &&
                  formik.values.cartItems.length ? (
                    <>
                      {formik.values.cartItems.map((cartItem, itemId) => {
                        return (
                          <div className="cart-items-wrapper" key={itemId}>
                            <GridContainer>
                              <GridItem xs={3} lg={3}>
                                <CustomInput
                                  labelText="CompanyName"
                                  id={`${cartItem.companyName}${itemId}`}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "text",
                                    name: "companyName",
                                    value:
                                      formik.values.cartItems[itemId]
                                        .companyName,
                                    onChange: (e) => {
                                      const newValues = formik.values.cartItems.map(
                                        (ci, cd) => {
                                          if (itemId === cd) {
                                            return {
                                              ...ci,
                                              companyName: e.target.value,
                                            };
                                          } else {
                                            return { ...ci };
                                          }
                                        }
                                      );
                                      formik.setFieldValue(
                                        "cartItems",
                                        newValues
                                      );
                                    },
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={4} lg={4}>
                                <CustomInput
                                  labelText="EventID"
                                  id={`${cartItem.eventID}${itemId}`}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "text",
                                    value:
                                      formik.values.cartItems[itemId].eventID,
                                    onChange: (e) => {
                                      const newValues = formik.values.cartItems.map(
                                        (ci, cd) => {
                                          if (itemId === cd) {
                                            return {
                                              ...ci,
                                              eventID: e.target.value,
                                            };
                                          } else {
                                            return { ...ci };
                                          }
                                        }
                                      );
                                      formik.setFieldValue(
                                        "cartItems",
                                        newValues
                                      );
                                    },
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={2} lg={2}>
                                <CustomInput
                                  labelText="Currency"
                                  id={`${cartItem.cartItemCurrency}${itemId}`}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "text",
                                    name: "cartItemCurrency",
                                    value:
                                      formik.values.cartItems[itemId]
                                        .cartItemCurrency,
                                    onChange: (e) => {
                                      const newValues = formik.values.cartItems.map(
                                        (ci, cd) => {
                                          if (itemId === cd) {
                                            return {
                                              ...ci,
                                              cartItemCurrency: e.target.value,
                                            };
                                          } else {
                                            return { ...ci };
                                          }
                                        }
                                      );
                                      formik.setFieldValue(
                                        "cartItems",
                                        newValues
                                      );
                                    },
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} lg={3}>
                                <CustomInput
                                  labelText="Employee Name"
                                  id={`${cartItem.cartItemName}${itemId}`}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "text",
                                    name: "cartItemName",
                                    value:
                                      formik.values.cartItems[itemId]
                                        .cartItemName,
                                    onChange: (e) => {
                                      const newValues = formik.values.cartItems.map(
                                        (ci, cd) => {
                                          if (itemId === cd) {
                                            return {
                                              ...ci,
                                              cartItemName: e.target.value,
                                            };
                                          } else {
                                            return { ...ci };
                                          }
                                        }
                                      );
                                      formik.setFieldValue(
                                        "cartItems",
                                        newValues
                                      );
                                    },
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} lg={3}>
                                <CustomInput
                                  labelText="Employee Position"
                                  id={`${cartItem.cartItemPosition}${itemId}`}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "text",
                                    name: "cartItemPosition",
                                    value:
                                      formik.values.cartItems[itemId]
                                        .cartItemPosition,
                                    onChange: (e) => {
                                      const newValues = formik.values.cartItems.map(
                                        (ci, cd) => {
                                          if (itemId === cd) {
                                            return {
                                              ...ci,
                                              cartItemPosition: e.target.value,
                                            };
                                          } else {
                                            return { ...ci };
                                          }
                                        }
                                      );
                                      formik.setFieldValue(
                                        "cartItems",
                                        newValues
                                      );
                                    },
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} lg={3}>
                                <CustomInput
                                  labelText="Price"
                                  id={`${cartItem.cartItemPrice}${itemId}`}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "text",
                                    name: "cartItemPrice",
                                    value:
                                      formik.values.cartItems[itemId]
                                        .cartItemPrice,
                                    onChange: (e) => {
                                      const newValues = formik.values.cartItems.map(
                                        (ci, cd) => {
                                          if (itemId === cd) {
                                            return {
                                              ...ci,
                                              cartItemPrice: e.target.value,
                                            };
                                          } else {
                                            return { ...ci };
                                          }
                                        }
                                      );
                                      formik.setFieldValue(
                                        "cartItems",
                                        newValues
                                      );
                                    },
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={3} lg={3}>
                                <CustomInput
                                  labelText="VAT"
                                  id={`${cartItem.cartItemVat}${itemId}`}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "text",
                                    name: "cartItemVat",
                                    value:
                                      formik.values.cartItems[itemId]
                                        .cartItemVat,
                                    onChange: (e) => {
                                      const newValues = formik.values.cartItems.map(
                                        (ci, cd) => {
                                          if (itemId === cd) {
                                            return {
                                              ...ci,
                                              cartItemVat: e.target.value,
                                            };
                                          } else {
                                            return { ...ci };
                                          }
                                        }
                                      );
                                      formik.setFieldValue(
                                        "cartItems",
                                        newValues
                                      );
                                    },
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </GridItem>
                <GridItem xs={12} lg={12}>
                  <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={formik.isSubmitting}
                    >
                      Create Order
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
        {/* {classicModal.isVisible && (
          <CreateTestimonial
            classicModal={classicModal}
            setClassicModal={setClassicModal}
          />
        )} */}
      </GridItem>
    </GridContainer>
  );
};

export default CreateOrder;
