import React, { useState } from "react";
import PropTypes from "prop-types";
import superstaffLogo from "../../assets/img/logo.jpg";
import cgkLogo from "../../assets/img/cgk_logo_new.png";
import cgkEmployerSign from "../../assets/img/cgk_sign_new.jpeg";
import html2PDF from "jspdf-html2canvas";
import moment from "moment";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import API from "variables/api";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const ContractPreviewModal = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [contracturl, setcontracturl] = useState("");
  const [contractTexts, setContractTexts] = useState(props?.contract ?? "");

  const download = async () => {
    try {
      setIsLoading(true);
      const user = await API.post("contracts/createContractPDF", {
        contract: `<!DOCTYPE html><html><head><title>HTML content</title></head><body>
            <div id="contract_final" class="clearfix" style="padding: 15px;">${contractTexts}</div>
        </body></html>`,
      }).then((res) => {
        setIsLoading(false);
        props?.onSave(res.data.contract);
        // setcontracturl(res.data.contract);
      }).catch((err) => {
        setIsLoading(false);
      })
    } catch (error) {
      setIsLoading(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  const parseDynamicVariableToContract = async () => {
    let currentDate = moment().format("DD.MM.YYYY");
    let contractData = props?.contract ?? "";
    let superstaffLogoBaseData = await getBase64FromUrl(superstaffLogo);
    let cgkLogoBaseData = await getBase64FromUrl(cgkLogo);
    let cgkEmployerSignBaseData = await getBase64FromUrl(superstaffLogo);

    let precontent = `<div style="display:flex;justify-content:space-between;margin-bottom:24px">
                  <div>
                    <img src="${superstaffLogoBaseData}" style="width:100px" alt="" />
                  </div>
                  <div>
                    <img src="${cgkLogoBaseData}" style="width:100px" alt="" />
                  </div>
                </div>`;
    if (contractData) {
      const mappingVariables = [
        // { key: "{{employerName}}", value: "companyName" },
        // {
        //   key: "{{employeeName}}",
        //   value: `formik?.values?.lName`,
        // },
        // // { key: '{{priceTotal}}', value: `.....` },
        // {
        //   key: "{{employeeSSN}}",
        //   value: "jobPosition?.bookedStaffDetails?.ssn",
        // },
        // {
        //   key: "{{employeeBankAccountOwner}}",
        //   value: "jobPosition?.bookedStaffDetails?.issuedName",
        // },
        // {
        //   key: "{{employeeBankName}}",
        //   value: "jobPosition?.bookedStaffDetails?.nameOfInstitute",
        // },
        // {
        //   key: "{{employeeBankCode}}",
        //   value: "jobPosition?.bookedStaffDetails?.iban",
        // },
        // {
        //   key: "{{employeeBankNumber}}",
        //   value: "jobPosition?.bookedStaffDetails?.bic",
        // },
        { key: "{{date}}", value: currentDate },
        // {
        //   key: "{{employeeCity}}",
        //   value: "user?.employee?.city",
        // },
        // {
        //   key: "{{employeeSignature}}",
        //   value: `<img src="${cgkSign}" alt="formik?.values?.fName" style="width:175px;" />`,
        //   //  value: `!['${formik?.values?.fName}'](${employeeSign} "${formik?.values?.fName}")`,
        // },
        // {
        //   key: "{{employerSignature}}",
        //   value: `<img src="${cgkEmployerSignBaseData}" alt="formik?.values?.fName" style="width:175px;" />`,
        //   //   value: `![KK_Stempel Und Unterschrift CGK GmbH & Co KG](${cgkSign} "KK_Stempel Und Unterschrift CGK GmbH & Co KG")`,
        // },
      ];
      mappingVariables?.map((item) => {
        contractData = contractData.replaceAll(item.key, `${item.value}`);
        return item;
      });
    }
    if (precontent !== '') {
      contractData = `${precontent} <div>${contractData}</div>`;
    }
    setContractTexts(contractData);
  };

  React.useEffect(() => {
    parseDynamicVariableToContract();
  }, [props?.contract]);

  return (
    <>
      <Dialog
        classes={{
          root: classes.left + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={props?.showContractModal.visible}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          style: {
            maxWidth: "90%",
            width: "100%",
          },
        }}
        // onClose={() =>
        //   props?.setShowContractModal({ ...showContractModal, visible: false })
        // }
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() =>
              !isLoading && props?.setShowContractModal({ ...props?.showContractModal, visible: false })
            }
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3>Contract</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <>
            <p className="small-text text-uppercase d-flex">
              <strong>contract</strong>
            </p>
            <div className="contract-details">
              <div
                id="contract_final"
                className="clearfix"
                style={{ padding: "15px" }}
              >
                <div dangerouslySetInnerHTML={{ __html: contractTexts }} />
                {/* <ReactMarkdown
                    children={contractTexts?.toString("markdown")}
                    components={{
                      img: ({ node, ...props }) => (
                        <img style={{ maxWidth: "200px" }} {...props} />
                      ),
                    }}
                  /> */}
              </div>
            </div>
            <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
              {/* <Button
                type="button"
                Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // props?.setShowContractModal({
                  //   ...showContractModal,
                  //   visible: false,
                  // });
                  // props?.onSave(props?.contract);
                }}
              >
                Save
              </Button> */}
              {isLoading && <CircularProgress />}
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={download}
                disabled={isLoading}
              >
                {isLoading ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

ContractPreviewModal.propTypes = {
  classicModal: PropTypes.bool,
  setClassicModal: PropTypes.func,
  contract: PropTypes.any,
  onSave: PropTypes.func,
  showContractModal: PropTypes.any, 
  setShowContractModal: PropTypes.any,
};

export default ContractPreviewModal;
