import { contractsConstants } from "../actions/types";

const initialState = { loading: false, data: [], error: null };

const contractReducer = (state = initialState, action) => {
  switch (action.type) {
    case contractsConstants.GET_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: "",
      };
    case contractsConstants.GET_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.contracts,
        error: "",
      };
    case contractsConstants.GET_CONTRACT_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };
    case contractsConstants.CREATE_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case contractsConstants.CREATE_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case contractsConstants.CREATE_CONTRACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case contractsConstants.UPDATE_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case contractsConstants.UPDATE_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case contractsConstants.UPDATE_CONTRACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case contractsConstants.DELETE_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case contractsConstants.DELETE_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case contractsConstants.DELETE_CONTRACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return { ...state };
  }
};

export default contractReducer;
